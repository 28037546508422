import React from "react";
import { useAi } from "../../hooks";

export const AskAIButton = ({ children, allowClose = true }) => {
    const { isOpen, setIsOpen } = useAi();

    return (
        <div onClick={() => setIsOpen(allowClose ? !isOpen : true)} className="cursor-pointer">{children}</div>
    );
};
