import React, { useEffect, useState } from 'react';
import { useApp, useUser } from '../../hooks';
import { Button } from '../Button';
import error_icon from "../../img/error.svg";


export const Verify = ({ email, handleCancelVerify }) => {
    const [code, setCode] = useState(new Array(6).fill(''));
    const [error, setError] = useState(null);
    const { setToken } = useUser();
    const { REACT_APP_SERVER } = process.env;

    const handleInput = (index, event) => {
        const { value } = event.target;
        if (/^\d$/.test(value)) {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);

            if (index < 5) {
                document.getElementById(`input-${index + 1}`).focus();
            }
        }
    };

    const handleKeyDown = (index, event) => {
        if (event.key === 'Backspace' && index > 0 && event.target.value === '') {
            document.getElementById(`input-${index - 1}`).focus();
        }
    };

    const handlePaste = (event) => {
        event.preventDefault();
        const paste = (event.clipboardData || window.clipboardData).getData('text');
        const pasteArray = paste.split('').slice(0, 6);
        const newCode = [...code];
        pasteArray.forEach((char, index) => {
            if (/^\d$/.test(char)) {
                newCode[index] = char;
                const input = document.getElementById(`input-${index}`);
                if (input) {
                    input.value = char;
                }
            }
        });
        setCode(newCode);
        const nextInput = document.getElementById(`input-${pasteArray.length}`);
        if (nextInput) {
            nextInput.focus();
        }
    };

    useEffect(() => {
        if (/^\d{6}$/.test(code.join(''))) {
            handleVerify();
        }
    }, [code])

    const handleVerify = async () => {
        if (! /^\d{6}$/.test(code.join(''))) {
            setError('Uh-oh! The code is incomplete. Check and try again.');
            return
        }
        try {
            const response = await fetch(`${REACT_APP_SERVER}/auth/verify-code`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, code: code.join('') }),
            });

            if (!response.ok) {
                setError('Uh-oh! The code is incorrect. Let’s try that again!')
            }

            const data = await response.json();
            const { token } = data;
            if (token && token !== 'undefined') {
                setToken(data.token);
                localStorage.setItem('tkgw', data.token);
                handleCancelVerify();
            } else {
                setError('Uh-oh! The code is incorrect. Let’s try that again!')
            }
        } catch (error) {
            setError('Something went wrong. Give it another try in a bit?')
        }
    };

    return (
        <>
            <p className="font-semibold text-[18px] text-gray-02 mb-[6px]">Join Webcrumbs AI!</p>
            <p className="font-regular mb-[24px] text-gray-05">Check your inbox or your spam folder for our code.</p>
            <div className="flex justify-between">
                {Array.from({ length: 6 }).map((_, index) => (
                    <input
                        key={index}
                        id={`input-${index}`}
                        type="text"
                        maxLength="1"
                        className="w-[51px] h-[44px] border border-gray-10 rounded text-center"
                        onChange={(e) => handleInput(index, e)}
                        onKeyDown={(e) => handleKeyDown(index, e)}
                        onPaste={(e) => handlePaste(e)}
                        pattern="[0-9]"
                        inputMode="numeric"
                        onInput={(e) => {
                            if (!/^\d$/.test(e.target.value)) {
                                e.target.value = '';
                            }
                        }}
                    />
                ))}
            </div>
            {error && <div className="flex flex-row items-center gap-[3px] mt-[4px] text-[#F14A3E]"><img src={error_icon} alt="error icon" />{error}</div>}
            <div className="flex justify-between mt-[32px]">
                <Button className="rounded" label="Cancel" onClick={handleCancelVerify} />
                <Button primary={true} className="rounded" label="Continue" onClick={handleVerify} />
            </div>
        </>
    );
};
export default Verify;
