import React, { useEffect, useState } from 'react';
import { useSnapshot } from '../hooks';

import undo_icon from "../img/icons/undo_icon.svg";
import redo_icon from "../img/icons/redo_icon.svg";
import { Button } from './Button';
import { Tooltip } from './Tooltip';

export const UndoRedo = () => {
    const { undo, redo, cursor, getUndoRedoInfo } = useSnapshot();

    const [undoRedoInfo, setUndoRedoInfo] = useState({
        canUndo: false,
        canRedo: false,
        currentCursor: null,
        totalStates: null
    });

    useEffect(() => {
        const fetchUndoRedoInfo = async () => {
            const info = await getUndoRedoInfo();
            setUndoRedoInfo(info);
        };

        fetchUndoRedoInfo();
    }, [cursor, getUndoRedoInfo]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            const isMac = navigator.userAgent.includes('Mac OS');
            const undoKeys = isMac ? (event.metaKey && !event.shiftKey && event.key === 'z') : (event.crtlKey && event.key === 'z');
            const redoKeys = isMac ? (event.metaKey && event.shiftKey && event.key === 'z') : (event.crtlKey && event.key === 'y');
            if (undoKeys) {
                event.preventDefault();
                if (undoRedoInfo.canUndo) {
                    undo();
                    window.history.pushState({}, '', '');
                }
            } else if (redoKeys) {
                event.preventDefault();
                if (undoRedoInfo.canRedo) {
                    redo();
                    window.history.pushState({}, '', '');
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        const handlePopState = (event) => {
            if (event.state) {
                if (undoRedoInfo.canUndo) {
                    undo();
                } else if (undoRedoInfo.canRedo) {
                    redo();
                }
            }
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('popstate', handlePopState);
        };
    }, [undo, redo, undoRedoInfo]);

    const { canUndo, canRedo } = undoRedoInfo;

    return (
        <div className="bg-white rounded flex items-center">
            <Tooltip position="center" message={canUndo ? "Undo" : "Nothing to undo"}>
                <Button icon={undo_icon} alt="Undo Icon" className="rounded-l border-r-0 hover:border-r-0" disabled={!canUndo} onClick={undo} />
            </Tooltip>
            <Tooltip position="center" message={canRedo ? "Redo" : "Nothing to redo"}>
                <Button icon={redo_icon} alt="Redo Icon" className="rounded-r" disabled={!canRedo} onClick={redo} />
            </Tooltip>
        </div>
    );
};
