import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// import { MonitorProvider } from './contexts/MonitorContext';
// import { UserProvider } from './contexts/UserContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
  // <UserProvider>
  //   <MonitorProvider>
  //     <App />
  //   </MonitorProvider>
  // </UserProvider>
);