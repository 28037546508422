import { Button } from "./Button";
import code from "../img/code.svg";
import reactIcon from "../img/react.svg";
import angularIcon from "../img/angular.svg";
import vueIcon from "../img/vue.svg";
import svelteIcon from "../img/svelte.svg";
import htmlIcon from "../img/html.svg";
import cssIcon from "../img/css.svg";
import React, { useState, useRef } from "react";
import { useApp, useCustomization } from "../hooks";

export const CopyCode = () => {
    const { messages } = useCustomization();
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const timeoutRef = useRef(null);
    const { codeOpen, setCodeOpen } = useApp();

    const handleClick = () => {
        setCodeOpen(!codeOpen);
    }

    const handleMouseEnter = () => {
        clearTimeout(timeoutRef.current);
        setIsDropdownVisible(true);
    };

    const handleMouseLeave = () => {
        timeoutRef.current = setTimeout(() => {
            setIsDropdownVisible(false);
        }, 200);
    };

    const toggleDropdown = () => {
        setIsDropdownVisible(!isDropdownVisible);
    };

    return (
        <div
            className="relative"
            // onMouseEnter={handleMouseEnter}
            // onMouseLeave={handleMouseLeave}
        >
            <Button
                className="border rounded h-[40px] cursor-pointer"
                label={messages.navbar.get_code}
                icon={code}
                onClick={handleClick}
            />
            {/* {isDropdownVisible && (
                <div
                    id="dropdown"
                    className="absolute top-[46px] z-100 w-full bg-white divide-y divide-gray-100 rounded-lg border shadow"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <ul
                        className="text-[13px] text-gray-800"
                        aria-labelledby="dropdownDefaultButton"
                    >
                        <li>
                            <a
                                href="#"
                                className="flex items-center px-4 py-2 hover:bg-gray-100"
                            >
                                <img src={reactIcon} alt="React" className="w-4 h-4 mr-2" />{" "}
                                React
                            </a>
                        </li>
                        <li>
                            <a
                                href="#"
                                className="flex items-center px-4 py-2 hover:bg-gray-100 border-t"
                            >
                                <img src={angularIcon} alt="Angular" className="w-4 h-4 mr-2" />
                                Angular
                            </a>
                        </li>
                        <li>
                            <a
                                href="#"
                                className="flex items-center px-4 py-2 hover:bg-gray-100 border-t"
                            >
                                <img src={vueIcon} alt="Vue" className="w-4 h-4 mr-2" />
                                Vue
                            </a>
                        </li>
                        <li>
                            <a
                                href="#"
                                className="flex items-center px-4 py-2 hover:bg-gray-100 border-t"
                            >
                                <img src={svelteIcon} alt="Svelte" className="w-4 h-4 mr-2" />
                                Svelte
                            </a>
                        </li>
                        <li>
                            <a
                                href="#"
                                className="flex items-center px-4 py-2 hover:bg-gray-100 border-t"
                            >
                                <img src={htmlIcon} alt="HTML" className="w-4 h-4 mr-2" />
                                HTML
                            </a>
                        </li>
                        <li>
                            <a
                                href="#"
                                className="flex items-center px-4 py-2 hover:bg-gray-100 border-t"
                            >
                                <img src={cssIcon} alt="CSS" className="w-4 h-4 mr-2" />
                                CSS
                            </a>
                        </li>
                    </ul>
                </div>
            )} */}
        </div>
    );
};
