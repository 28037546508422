import {
    hexToRgbArray,
    createShade,
    rgbArrayToHex,
    rgbArrayToCssString,
    rgbArrayToObject1,
    getWhiteTextContrastRatio,
    getDarkTextContrastRatio,
} from "./colorCommon";
import { colorProfiles } from "../../constants/colorProfiles.js";

export function generateLayerShades(mainHexColor, colorName, selectedProfileTone, selectedProfile) {
    let mainRgbColor = hexToRgbArray(mainHexColor);
    let colorNamePrefix = "color";
    let clonedData = JSON.parse(JSON.stringify(colorProfiles[selectedProfile]));
    let shadesPalette = clonedData[selectedProfileTone];

    if (colorName) {
        if (selectedProfile === "orbit") {
            colorNamePrefix = colorName.toLowerCase();
        } else if (selectedProfile === "atlassian") {
            colorNamePrefix = colorName.charAt(0).toUpperCase();
        } else if (selectedProfile === "material") {
            colorNamePrefix = colorName.toLowerCase() + "-";
        } else if (selectedProfile === "ant-design") {
            colorNamePrefix = colorName.toLowerCase() + "-";
        }
    } else {
        colorNamePrefix = "";
    }

    for (let i = 0; i < shadesPalette.length; i++) {
        let colorRGB = createShade(mainRgbColor, shadesPalette[i].brightness);
        let colorRgbString = rgbArrayToCssString(colorRGB);
        let colorRgbObject = rgbArrayToObject1(colorRGB);
        let colorHex = rgbArrayToHex(colorRGB);
        let token = colorNamePrefix + shadesPalette[i].tokenSuffix;

        let additionalSettings = {
            rgbArray: colorRGB,
            hex: colorHex,
            rgb: colorRgbString,
            rgbObject: colorRgbObject,
        };
        shadesPalette[i] = { ...shadesPalette[i], ...additionalSettings };
        shadesPalette[i].token = token;
    }
    return shadesPalette;
}

export function generateTokens(fullPalette) {
    let clonedPalette = JSON.parse(JSON.stringify(fullPalette));

    for (let i = 0; i < clonedPalette.length; i++) {
        ["brightness", "rgbArray", "rgbObject", "tokenSuffix"].forEach(
            (e) => delete clonedPalette[i][e]
        );
    }

    return clonedPalette;
}

export { getWhiteTextContrastRatio, getDarkTextContrastRatio };
