import { useImage } from '../../hooks';
import left from "../../img/left.svg";
import { ImagePanel } from '../Image/ImagePanel';

export const LeftSecondaryPanel = () => {
    const { isOpen, setIsOpen } = useImage();

    const toggleMenu = () => {
        setIsOpen(false);
    };

    return (
        <div className={`absolute bg-white flex flex-col relative transition-transform duration-300 ease-in-out transform ${isOpen ? 'translate-x-0' : '-translate-x-[calc(100%+32px)]'} menu`}>
            <div className="w-[300px] flex-shrink-0 transition-width duration-300 ease-in-out">
                <button className="m-[6px] absolute right-[-20px] top-1/2 transform -translate-y-1/2 p-1 bg-white rounded-[100%] border border-gray-10" onClick={toggleMenu}>
                    <img src={left} alt="Left icon" />
                </button>
                <div className="p-[20px]">
                    <ImagePanel />
                </div>
            </div>
        </div>
    );
};
