import emptystate from "./img/empty.svg";
import loadsession from "./img/directions.svg";
import square_logo from "./img/square_logo.svg";
import frontend_ai from "../img/frontend_ai.svg";

export const images = {
    "main_logo": <div className="flex flex-row gap-[6px] items-center justify-center">
            <img src={square_logo} alt="Logo" className="h-[24px]" />
            <img src={frontend_ai} alt="Logo" className="h-[14px]" />
        </div>,
    "main_logo_vertical": <div className="flex flex-col gap-[6px] items-center justify-center">
            <img src={square_logo} alt="Logo" className="h-[24px]" />
            <img src={frontend_ai} alt="Logo" className="h-[14px]" />
        </div>,
    "empty_state_first_access": <img src={emptystate} alt="Empty state illustration" className="w-[255px] h-[120px]" />,
    "empty_state_returning_user": <img src={loadsession} alt="Empty state illustration" className="w-[255px] h-[120px]" />
}