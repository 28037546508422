import { Spinner } from "./Spinner"

export const Button = ({ alt, icon, loading, disabled, className, label, primary, plain, ...rest }) => {
    const color = primary ?
        {
            normal: 'bg-main-orange-01 shadow-xs',
            hover: 'hover:bg-main-orange-03',
            text: 'text-white font-semibold font-semibold'
        } :
        plain ?
            {
                normal: 'bg-white',
                hover: 'hover:text-main-orange-02',
                text: 'text-main-orange-01 font-[600]'
            } :
            {
                normal: 'bg-white border border-gray-10 shadow-xs',
                hover: 'hover:bg-gray-11 hover:border hover:border-gray-10',
                text: 'text-gray-04 font-semibold'
            }

    return (
        <button className={`
                h-[40px]
                flex flex-row 
                p-[10px] gap-[6px] 
                justify-center items-center 
                ${(icon && !label) ? '' : 'px-[16px]'}
                ${className} 
                ${!disabled ? color.hover : 'opacity-50'}
                ${!disabled ? 'cursor-pointer' : 'opacity-50'}
                ${color.normal}
            `}
            disabled={disabled}
            {...rest}
        >
            {icon &&
                <div className="w-5 h-5 relative">
                    {
                        loading ?
                            <Spinner />
                            :
                            <img src={icon} alt={alt} className={disabled ? 'opacity-70' : 'opacity-100'} />
                    }
                </div>
            }
            {label &&
                <div className={color.text}>
                    {label}
                </div>
            }
        </button>
    )
}