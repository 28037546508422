import React, { useEffect, useState } from 'react';

export const SVG = ({ src, fill, replace, width, height, ...rest }) => {
  const [svgContent, setSvgContent] = useState(null);

  useEffect(() => {
    fetch(src)
      .then((response) => response.text())
      .then((text) => {
        let updatedSVG
        updatedSVG = text
        if (fill) {
          updatedSVG = updatedSVG.replace(/fill="#[^"]*"/g, `fill="${fill}"`)
          updatedSVG = updatedSVG.replace(/fill: #[^"]*;/g, `fill: ${fill};`)
        }
        if (width) {
          updatedSVG = updatedSVG.replace(/width="[^"]*"/g, `width="${width}"`)
        }
        if (height) {
          updatedSVG = updatedSVG.replace(/height="[^"]*"/g, `height="${height}"`);
        }
        if (replace) {
          replace.forEach(([oldValue, newValue]) => {
            updatedSVG = updatedSVG.replace(new RegExp(oldValue, 'g'), newValue);
          });
        }
        setSvgContent(updatedSVG);
      });
  }, [src, fill, width, height]);

  return (
    <div
      dangerouslySetInnerHTML={{ __html: svgContent }}
      {...rest}
    />
  );
};
