// src/components/Code/ComponentCode.js
import React, { useState } from "react";

import copy from '../../img/copy.svg';
import check from '../../img/check.svg';
import { useCode } from "../../hooks";
import { Tooltip } from "../Tooltip";
import { CodeEditor } from "./CodeEditor";

export const ComponentCode = ({ size }) => {
    const { code } = useCode();
    const [copied, setCopied] = useState(false);

    const handleClick = () => {
        navigator.clipboard.writeText(code)
            .then(() => {
                setCopied(true);
                setTimeout(() => {
                    setCopied(false);
                }, 600);
            });
    };

    return (
        <div className={`${size} h-full relative border-t border-gray-03 flex flex-col overflow-hidden`}>
            <div className={`px-[24px] pt-[16px] pb-[8px] bg-code-01 focus:outline-none text-base font-semibold leading-normal flex items-center`}>
                Your Component Code
                <div className="ml-[8px]">
                    <Tooltip position="center">
                        Here's the code of your component. <br />
                        You can edit it yourself or Ask AI for<br />
                        changes.
                    </Tooltip>
                </div>
            </div>
            <CodeEditor />
            <div
                className="absolute top-0 right-0 p-[16px] opacity-50 hover:opacity-100 transition-opacity cursor-pointer"
                onClick={handleClick}
            >
                <div className="w-[32px] h-[32px] rounded bg-code-02 flex items-center justify-center">
                    <img src={copied ? check : copy} className="h-[20px] w-[20px]" />
                </div>
            </div>
        </div>
    )
}