export const canChangeTheme = true;

export const themes = [
    {
        "name": "BMW",
        "fontFamily": {
            "fontFaces": "@font-face{font-family:bmw;src:url(https://bmwcaravenue.fr/_next/static/media/BMWTypeNextTT-Regular.8748d0e9.woff)format(\"woff\")} @font-face{font-family:bmw-bold;src:url(https://bmwcaravenue.fr/_next/static/media/BMWTypeNextTT-Bold.56d6f32c.woff)format(\"woff\")} @font-face{font-family:bmw-light;src:url(https://bmwcaravenue.fr/_next/static/media/BMWTypeNextTT-Light.feb61d25.woff)format(\"woff\")} @font-face{font-family:bmw-extralight;src:url(https://bmwcaravenue.fr/_next/static/media/BMWTypeNext-Thin.23e85349.otf)format(\"opentype\")}",
            "baseValue": [
                "bmw",
                "bmw-bold"
            ],
            "sans": [
                "bmw",
                "Helvetica",
                "Arial",
                "sans-serif",
                "\"AppleColorEmoji\"",
                "\"SegoeUIEmoji\"",
                "\"SegoeUISymbol\"",
                "\"NotoColorEmoji\""
            ]
        },
        "extend": {
            "fontFamily": {
                "title": [
                    "bmw-bold",
                    "ui-sans-serif",
                    "system-ui",
                    "sans-serif",
                    "\"Apple Color Emoji\"",
                    "\"Segoe UI Emoji\"",
                    "\"Segoe UI Symbol\"",
                    "\"Noto Color Emoji\""
                ],
                "body": [
                    "bmw",
                    "ui-sans-serif",
                    "system-ui",
                    "sans-serif",
                    "\"Apple Color Emoji\"",
                    "\"Segoe UI Emoji\"",
                    "\"Segoe UI Symbol\"",
                    "\"Noto Color Emoji\""
                ]
            },
            "colors": {
                "neutral": {
                    "50": "#FFFFFF",
                    "100": "#F7F7F7",
                    "200": "#F0F0F0",
                    "300": "#E8E8E8",
                    "400": "#E0E0E0",
                    "500": "#D9D9D9",
                    "600": "#D1D1D1",
                    "700": "#C9C9C9",
                    "800": "#C2C2C2",
                    "900": "#BABABA",
                    "DEFAULT": "#FFFFFF",
                    "baseValue": "#FFFFFF"
                },
                "primary": {
                    "50": "#B0CBF0",
                    "100": "#99BCEC",
                    "200": "#77A5E5",
                    "300": "#558FDF",
                    "400": "#3378D8",
                    "500": "#1C69D4",
                    "600": "#195FBF",
                    "700": "#154F9F",
                    "800": "#113F7F",
                    "900": "#0D2F5F",
                    "DEFAULT": "#1C69D4",
                    "baseValue": "#1C69D4",
                    "replace": true
                },
                "baseValue": {
                    "blue": "#1C69D4"
                },
                "blue": {
                    "50": "#1C69D4",
                    "100": "#1B66CE",
                    "200": "#1A63C7",
                    "300": "#1960C1",
                    "400": "#195CBB",
                    "500": "#1859B4",
                    "600": "#1756AE",
                    "700": "#1653A7",
                    "800": "#1550A1",
                    "900": "#144D9B",
                    "DEFAULT": "#1C69D4"
                }
            }
        },
        "fontSize": {
            "baseValue": 16,
            "xs": [
                "12px",
                {
                    "lineHeight": "16px"
                }
            ],
            "sm": [
                "14px",
                {
                    "lineHeight": "20px"
                }
            ],
            "base": [
                "16px",
                {
                    "lineHeight": "24px"
                }
            ],
            "lg": [
                "18px",
                {
                    "lineHeight": "28px"
                }
            ],
            "xl": [
                "20px",
                {
                    "lineHeight": "28px"
                }
            ],
            "2xl": [
                "24px",
                {
                    "lineHeight": "32px"
                }
            ],
            "3xl": [
                "30px",
                {
                    "lineHeight": "36px"
                }
            ],
            "4xl": [
                "36px",
                {
                    "lineHeight": "40px"
                }
            ],
            "5xl": [
                "48px",
                {
                    "lineHeight": "16px"
                }
            ],
            "6xl": [
                "60px",
                {
                    "lineHeight": "16px"
                }
            ],
            "7xl": [
                "72px",
                {
                    "lineHeight": "16px"
                }
            ],
            "8xl": [
                "96px",
                {
                    "lineHeight": "16px"
                }
            ],
            "9xl": [
                "128px",
                {
                    "lineHeight": "16px"
                }
            ]
        },
        "borderRadius": {
            "baseValue": 3,
            "none": "0px",
            "sm": "1.5px",
            "DEFAULT": "3px",
            "md": "4.5px",
            "lg": "6px",
            "xl": "9px",
            "2xl": "12px",
            "3xl": "18px",
            "full": "9999px"
        },
        "spacing": {
            "0": "0px",
            "1": "4px",
            "2": "8px",
            "3": "12px",
            "4": "16px",
            "5": "20px",
            "6": "24px",
            "7": "28px",
            "8": "32px",
            "9": "36px",
            "10": "40px",
            "11": "44px",
            "12": "48px",
            "14": "56px",
            "16": "64px",
            "20": "80px",
            "24": "96px",
            "28": "112px",
            "32": "128px",
            "36": "144px",
            "40": "160px",
            "44": "176px",
            "48": "192px",
            "52": "208px",
            "56": "224px",
            "60": "240px",
            "64": "256px",
            "72": "288px",
            "80": "320px",
            "96": "384px",
            "baseValue": 4,
            "px": "1px",
            "0.5": "2px",
            "1.5": "6px",
            "2.5": "10px",
            "3.5": "14px"
        },
        "customCss": ""
    },
    {
        "name": "BMW Dark",
        "fontFamily": {
            "fontFaces": "@font-face{font-family:bmw;src:url(https://bmwcaravenue.fr/_next/static/media/BMWTypeNextTT-Regular.8748d0e9.woff)format(\"woff\")} @font-face{font-family:bmw-bold;src:url(https://bmwcaravenue.fr/_next/static/media/BMWTypeNextTT-Bold.56d6f32c.woff)format(\"woff\")} @font-face{font-family:bmw-light;src:url(https://bmwcaravenue.fr/_next/static/media/BMWTypeNextTT-Light.feb61d25.woff)format(\"woff\")} @font-face{font-family:bmw-extralight;src:url(https://bmwcaravenue.fr/_next/static/media/BMWTypeNext-Thin.23e85349.otf)format(\"opentype\")}",
            "baseValue": [
                "bmw",
                "bmw-bold"
            ],
            "sans": [
                "bmw",
                "Helvetica",
                "Arial",
                "sans-serif",
                "\"AppleColorEmoji\"",
                "\"SegoeUIEmoji\"",
                "\"SegoeUISymbol\"",
                "\"NotoColorEmoji\""
            ]
        },
        "extend": {
            "fontFamily": {
                "title": [
                    "bmw-bold",
                    "ui-sans-serif",
                    "system-ui",
                    "sans-serif",
                    "\"Apple Color Emoji\"",
                    "\"Segoe UI Emoji\"",
                    "\"Segoe UI Symbol\"",
                    "\"Noto Color Emoji\""
                ],
                "body": [
                    "bmw",
                    "ui-sans-serif",
                    "system-ui",
                    "sans-serif",
                    "\"Apple Color Emoji\"",
                    "\"Segoe UI Emoji\"",
                    "\"Segoe UI Symbol\"",
                    "\"Noto Color Emoji\""
                ]
            },
            "colors": {
                "neutral": {
                    "50": "#111111",
                    "100": "#101010",
                    "200": "#101010",
                    "300": "#0F0F0F",
                    "400": "#0F0F0F",
                    "500": "#0E0E0E",
                    "600": "#0E0E0E",
                    "700": "#0D0D0D",
                    "800": "#0D0D0D",
                    "900": "#0C0C0C",
                    "DEFAULT": "#111111",
                    "baseValue": "#111111",
                    "replace": true
                },
                "primary": {
                    "50": "#B0CBF0",
                    "100": "#99BCEC",
                    "200": "#77A5E5",
                    "300": "#558FDF",
                    "400": "#3378D8",
                    "500": "#1C69D4",
                    "600": "#195FBF",
                    "700": "#154F9F",
                    "800": "#113F7F",
                    "900": "#0D2F5F",
                    "DEFAULT": "#1C69D4",
                    "baseValue": "#1C69D4",
                    "replace": true
                },
                "baseValue": {
                    "white": "#111111",
                    "blue": "#1C69D4"
                },
                "white": {
                    "50": "#111111",
                    "100": "#101010",
                    "200": "#101010",
                    "300": "#0F0F0F",
                    "400": "#0F0F0F",
                    "500": "#0E0E0E",
                    "600": "#0E0E0E",
                    "700": "#0D0D0D",
                    "800": "#0D0D0D",
                    "900": "#0C0C0C",
                    "DEFAULT": "#111111"
                },
                "blue": {
                    "50": "#1C69D4",
                    "100": "#1B66CE",
                    "200": "#1A63C7",
                    "300": "#1960C1",
                    "400": "#195CBB",
                    "500": "#1859B4",
                    "600": "#1756AE",
                    "700": "#1653A7",
                    "800": "#1550A1",
                    "900": "#144D9B",
                    "DEFAULT": "#1C69D4"
                }
            }
        },
        "fontSize": {
            "baseValue": 16,
            "xs": [
                "12px",
                {
                    "lineHeight": "16px"
                }
            ],
            "sm": [
                "14px",
                {
                    "lineHeight": "20px"
                }
            ],
            "base": [
                "16px",
                {
                    "lineHeight": "24px"
                }
            ],
            "lg": [
                "18px",
                {
                    "lineHeight": "28px"
                }
            ],
            "xl": [
                "20px",
                {
                    "lineHeight": "28px"
                }
            ],
            "2xl": [
                "24px",
                {
                    "lineHeight": "32px"
                }
            ],
            "3xl": [
                "30px",
                {
                    "lineHeight": "36px"
                }
            ],
            "4xl": [
                "36px",
                {
                    "lineHeight": "40px"
                }
            ],
            "5xl": [
                "48px",
                {
                    "lineHeight": "16px"
                }
            ],
            "6xl": [
                "60px",
                {
                    "lineHeight": "16px"
                }
            ],
            "7xl": [
                "72px",
                {
                    "lineHeight": "16px"
                }
            ],
            "8xl": [
                "96px",
                {
                    "lineHeight": "16px"
                }
            ],
            "9xl": [
                "128px",
                {
                    "lineHeight": "16px"
                }
            ]
        },
        "borderRadius": {
            "baseValue": 3,
            "none": "0px",
            "sm": "1.5px",
            "DEFAULT": "3px",
            "md": "4.5px",
            "lg": "6px",
            "xl": "9px",
            "2xl": "12px",
            "3xl": "18px",
            "full": "9999px"
        },
        "spacing": {
            "0": "0px",
            "1": "4px",
            "2": "8px",
            "3": "12px",
            "4": "16px",
            "5": "20px",
            "6": "24px",
            "7": "28px",
            "8": "32px",
            "9": "36px",
            "10": "40px",
            "11": "44px",
            "12": "48px",
            "14": "56px",
            "16": "64px",
            "20": "80px",
            "24": "96px",
            "28": "112px",
            "32": "128px",
            "36": "144px",
            "40": "160px",
            "44": "176px",
            "48": "192px",
            "52": "208px",
            "56": "224px",
            "60": "240px",
            "64": "256px",
            "72": "288px",
            "80": "320px",
            "96": "384px",
            "baseValue": 4,
            "px": "1px",
            "0.5": "2px",
            "1.5": "6px",
            "2.5": "10px",
            "3.5": "14px"
        }
    },
    {
        "name": "Mini",
        "fontFamily": {
            "fontFaces": "@font-face{font-family:mini;src:url(https://bmwcaravenue.fr/_next/static/media/MINISerif-Regular.15bb0f86.woff)format(\"opentype\")} @font-face{font-family:mini-bold;src:url(https://bmwcaravenue.fr/_next/static/media/MINISerif-Bold.07e42086.woff)format(\"opentype\")} @font-face{font-family:mini-headline;src:url(https://bmwcaravenue.fr/_next/static/media/MINISerif-Headline.7df87c2a.woff)format(\"opentype\")}",
            "baseValue": [
                "mini",
                "mini-headline"
            ],
            "sans": [
                "Open Sans",
                "ui-sans-serif",
                "system-ui",
                "sans-serif",
                "\"Apple Color Emoji\"",
                "\"Segoe UI Emoji\"",
                "\"Segoe UI Symbol\"",
                "\"Noto Color Emoji\""
            ]
        },
        "extend": {
            "fontFamily": {
                "title": [
                    "mini-headline",
                    "ui-sans-serif",
                    "system-ui",
                    "sans-serif",
                    "\"Apple Color Emoji\"",
                    "\"Segoe UI Emoji\"",
                    "\"Segoe UI Symbol\"",
                    "\"Noto Color Emoji\""
                ],
                "body": [
                    "mini",
                    "ui-sans-serif",
                    "system-ui",
                    "sans-serif",
                    "\"Apple Color Emoji\"",
                    "\"Segoe UI Emoji\"",
                    "\"Segoe UI Symbol\"",
                    "\"Noto Color Emoji\""
                ]
            },
            "colors": {
                "neutral": {
                    "50": "#FFFFFF",
                    "100": "#F7F7F7",
                    "200": "#F0F0F0",
                    "300": "#E8E8E8",
                    "400": "#E0E0E0",
                    "500": "#D9D9D9",
                    "600": "#D1D1D1",
                    "700": "#C9C9C9",
                    "800": "#C2C2C2",
                    "900": "#BABABA",
                    "DEFAULT": "#FFFFFF",
                    "baseValue": "#FFFFFF"
                },
                "primary": {
                    "50": "#A6C9D4",
                    "100": "#8CBAC7",
                    "200": "#66A3B5",
                    "300": "#408CA2",
                    "400": "#1A748F",
                    "500": "#006583",
                    "600": "#005B76",
                    "700": "#004C62",
                    "800": "#003D4F",
                    "900": "#002D3B",
                    "DEFAULT": "#006583",
                    "baseValue": "#006583"
                }
            }
        },
        "fontSize": {
            "baseValue": 18,
            "xs": [
                "13.5px",
                {
                    "lineHeight": "18px"
                }
            ],
            "sm": [
                "15.75px",
                {
                    "lineHeight": "22.5px"
                }
            ],
            "base": [
                "18px",
                {
                    "lineHeight": "27px"
                }
            ],
            "lg": [
                "20.25px",
                {
                    "lineHeight": "31.5px"
                }
            ],
            "xl": [
                "22.5px",
                {
                    "lineHeight": "31.499999999999996px"
                }
            ],
            "2xl": [
                "27px",
                {
                    "lineHeight": "36px"
                }
            ],
            "3xl": [
                "33.75px",
                {
                    "lineHeight": "40.5px"
                }
            ],
            "4xl": [
                "40.5px",
                {
                    "lineHeight": "45px"
                }
            ],
            "5xl": [
                "54px",
                {
                    "lineHeight": "18px"
                }
            ],
            "6xl": [
                "67.5px",
                {
                    "lineHeight": "18px"
                }
            ],
            "7xl": [
                "81px",
                {
                    "lineHeight": "18px"
                }
            ],
            "8xl": [
                "108px",
                {
                    "lineHeight": "18px"
                }
            ],
            "9xl": [
                "144px",
                {
                    "lineHeight": "18px"
                }
            ]
        },
        "borderRadius": {
            "baseValue": 18,
            "none": "0px",
            "sm": "9px",
            "DEFAULT": "18px",
            "md": "27px",
            "lg": "36px",
            "xl": "54px",
            "2xl": "72px",
            "3xl": "108px",
            "full": "9999px"
        },
        "spacing": {
            "0": "0px",
            "1": "6px",
            "2": "12px",
            "3": "18px",
            "4": "24px",
            "5": "30px",
            "6": "36px",
            "7": "42px",
            "8": "48px",
            "9": "54px",
            "10": "60px",
            "11": "66px",
            "12": "72px",
            "14": "84px",
            "16": "96px",
            "20": "120px",
            "24": "144px",
            "28": "168px",
            "32": "192px",
            "36": "216px",
            "40": "240px",
            "44": "264px",
            "48": "288px",
            "52": "312px",
            "56": "336px",
            "60": "360px",
            "64": "384px",
            "72": "432px",
            "80": "480px",
            "96": "576px",
            "baseValue": 6,
            "px": "1px",
            "0.5": "3px",
            "1.5": "9px",
            "2.5": "15px",
            "3.5": "21px"
        }
    },
    {
        "name": "Mini Dark",
        "fontFamily": {
            "fontFaces": "@font-face{font-family:mini;src:url(https://bmwcaravenue.fr/_next/static/media/MINISerif-Regular.15bb0f86.woff)format(\"opentype\")} @font-face{font-family:mini-bold;src:url(https://bmwcaravenue.fr/_next/static/media/MINISerif-Bold.07e42086.woff)format(\"opentype\")} @font-face{font-family:mini-headline;src:url(https://bmwcaravenue.fr/_next/static/media/MINISerif-Headline.7df87c2a.woff)format(\"opentype\")}",
            "baseValue": [
                "mini",
                "mini-headline"
            ],
            "sans": [
                "Open Sans",
                "ui-sans-serif",
                "system-ui",
                "sans-serif",
                "\"Apple Color Emoji\"",
                "\"Segoe UI Emoji\"",
                "\"Segoe UI Symbol\"",
                "\"Noto Color Emoji\""
            ]
        },
        "extend": {
            "fontFamily": {
                "title": [
                    "mini-headline",
                    "ui-sans-serif",
                    "system-ui",
                    "sans-serif",
                    "\"Apple Color Emoji\"",
                    "\"Segoe UI Emoji\"",
                    "\"Segoe UI Symbol\"",
                    "\"Noto Color Emoji\""
                ],
                "body": [
                    "mini",
                    "ui-sans-serif",
                    "system-ui",
                    "sans-serif",
                    "\"Apple Color Emoji\"",
                    "\"Segoe UI Emoji\"",
                    "\"Segoe UI Symbol\"",
                    "\"Noto Color Emoji\""
                ]
            },
            "colors": {
                "neutral": {
                    "50": "#111111",
                    "100": "#101010",
                    "200": "#101010",
                    "300": "#0F0F0F",
                    "400": "#0F0F0F",
                    "500": "#0E0E0E",
                    "600": "#0E0E0E",
                    "700": "#0D0D0D",
                    "800": "#0D0D0D",
                    "900": "#0C0C0C",
                    "DEFAULT": "#111111",
                    "baseValue": "#111111"
                },
                "primary": {
                    "50": "#A6C9D4",
                    "100": "#8CBAC7",
                    "200": "#66A3B5",
                    "300": "#408CA2",
                    "400": "#1A748F",
                    "500": "#006583",
                    "600": "#005B76",
                    "700": "#004C62",
                    "800": "#003D4F",
                    "900": "#002D3B",
                    "DEFAULT": "#006583",
                    "baseValue": "#006583"
                }
            }
        },
        "fontSize": {
            "baseValue": 18,
            "xs": [
                "13.5px",
                {
                    "lineHeight": "18px"
                }
            ],
            "sm": [
                "15.75px",
                {
                    "lineHeight": "22.5px"
                }
            ],
            "base": [
                "18px",
                {
                    "lineHeight": "27px"
                }
            ],
            "lg": [
                "20.25px",
                {
                    "lineHeight": "31.5px"
                }
            ],
            "xl": [
                "22.5px",
                {
                    "lineHeight": "31.499999999999996px"
                }
            ],
            "2xl": [
                "27px",
                {
                    "lineHeight": "36px"
                }
            ],
            "3xl": [
                "33.75px",
                {
                    "lineHeight": "40.5px"
                }
            ],
            "4xl": [
                "40.5px",
                {
                    "lineHeight": "45px"
                }
            ],
            "5xl": [
                "54px",
                {
                    "lineHeight": "18px"
                }
            ],
            "6xl": [
                "67.5px",
                {
                    "lineHeight": "18px"
                }
            ],
            "7xl": [
                "81px",
                {
                    "lineHeight": "18px"
                }
            ],
            "8xl": [
                "108px",
                {
                    "lineHeight": "18px"
                }
            ],
            "9xl": [
                "144px",
                {
                    "lineHeight": "18px"
                }
            ]
        },
        "borderRadius": {
            "baseValue": 18,
            "none": "0px",
            "sm": "9px",
            "DEFAULT": "18px",
            "md": "27px",
            "lg": "36px",
            "xl": "54px",
            "2xl": "72px",
            "3xl": "108px",
            "full": "9999px"
        },
        "spacing": {
            "0": "0px",
            "1": "6px",
            "2": "12px",
            "3": "18px",
            "4": "24px",
            "5": "30px",
            "6": "36px",
            "7": "42px",
            "8": "48px",
            "9": "54px",
            "10": "60px",
            "11": "66px",
            "12": "72px",
            "14": "84px",
            "16": "96px",
            "20": "120px",
            "24": "144px",
            "28": "168px",
            "32": "192px",
            "36": "216px",
            "40": "240px",
            "44": "264px",
            "48": "288px",
            "52": "312px",
            "56": "336px",
            "60": "360px",
            "64": "384px",
            "72": "432px",
            "80": "480px",
            "96": "576px",
            "baseValue": 6,
            "px": "1px",
            "0.5": "3px",
            "1.5": "9px",
            "2.5": "15px",
            "3.5": "21px"
        }
    },
]

export const fontPairing = {
    'bmw': ['bmw', 'bmw-bold', 'bmw-light', 'bmw-extralight'],
    'bmw-bold': ['bmw', 'bmw-bold', 'bmw-light', 'bmw-extralight'],
    'bmw-light': ['bmw', 'bmw-bold', 'bmw-light', 'bmw-extralight'],
    'bmw-extralight': ['bmw', 'bmw-bold', 'bmw-light', 'bmw-extralight'],
    'mini': ['mini', 'mini-bold', 'mini-headline'],
    'mini-bold': ['mini', 'mini-bold', 'mini-headline'],
    'mini-headline': ['mini', 'mini-bold', 'mini-headline'],
    'Inter': ['Inter', 'Roboto', 'Open Sans'],
    'Open Sans': ['Open Sans', 'Lato', 'Josefin Sans'],
    'Lato': ['Lato', 'Open Sans', 'Roboto'],
    'Montserrat': ['Montserrat', 'Roboto', 'Open Sans'],
    'PT Sans': ['PT Sans', 'Roboto', 'Open Sans'],
};

export const fontsCdn = {
    'FontShare': 'https://api.fontshare.com/css?f[]=clash-display@400,700&display=swap',
    'Proxima Nova': 'https://cdn.jsdelivr.net/npm/font-proxima-nova@1.0.1/style.min.css',
    'Neue Haas': 'https://fonts.cdnfonts.com/css/neue-haas-grotesk-display-pro',
    'Helvetica Neue': 'https://fonts.cdnfonts.com/css/helvetica-neue-55'
}

export const customFonts = {
    "bmw": "@font-face{font-family:bmw;src:url(https://bmwcaravenue.fr/_next/static/media/BMWTypeNextTT-Regular.8748d0e9.woff)format(\"woff\")}",
    "bmw-bold": "@font-face{font-family:bmw-bold;src:url(https://bmwcaravenue.fr/_next/static/media/BMWTypeNextTT-Bold.56d6f32c.woff)format(\"woff\")}",
    "bmw-light": "@font-face{font-family:bmw-light;src:url(https://bmwcaravenue.fr/_next/static/media/BMWTypeNextTT-Light.feb61d25.woff)format(\"woff\")}",
    "bmw-extralight": "@font-face{font-family:bmw-extralight;src:url(https://bmwcaravenue.fr/_next/static/media/BMWTypeNext-Thin.23e85349.otf)format(\"opentype\")}",
    "mini": "@font-face{font-family:mini;src:url(https://bmwcaravenue.fr/_next/static/media/MINISerif-Regular.15bb0f86.woff)format(\"opentype\")}",
    "mini-bold": "@font-face{font-family:mini-bold;src:url(https://bmwcaravenue.fr/_next/static/media/MINISerif-Bold.07e42086.woff)format(\"opentype\")}",
    "mini-headline": "@font-face{font-family:mini-headline;src:url(https://bmwcaravenue.fr/_next/static/media/MINISerif-Headline.7df87c2a.woff)format(\"opentype\")}"
}