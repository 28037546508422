import React, { useState } from 'react';
import { AskAIButton } from '../AskAI/AskAIButton';
import clear from "../../img/clear.svg";
import ai_icon from "../../img/icons/Ai_icon.svg";
import template_icon from "../../img/template.svg";
import image from "../../img/image.svg";
import { useAi, useApp, useCode, useCustomization, useImage, useTheme } from '../../hooks';
import { Tooltip } from '../Tooltip';
import { SVG } from "../SVG";

export const LeftPrimaryPanel = () => {
  const { setIsOpen: setAiOpen } = useAi();
  const { template } = useCustomization();
  const { setCode, setSize, size } = useCode();
  const { setIsModalOpen, setModal, setEmptyState } = useApp();
  const { setCss } = useTheme();
  const { setIsOpen, isOpen } = useImage();
  const { setResult, setMessage } = useAi();

  const [isExpanded, setIsExpanded] = useState(false);


  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleConfirm = () => {
    setCode("<div></div>");
    setCss("");
    setResult(false);
    setMessage("");
    setEmptyState(false);
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setModal({
      title: 'Do you want to clear canvas?',
      text: `Once you confirm to clear the canvas, everything will be removed. 
      Don't worry, you can still go back and revert the changes. 
      Do you want to clear the canvas?`,
      cancelText: 'Cancel',
      confirmText: 'Clear canvas',
      onClose: handleCloseModal,
      onCancel: handleCancel,
      onConfirm: handleConfirm
    })
    setIsModalOpen(true);
  };

  const handleTemplate = () => {
    setCode(template);
    setSize({ ...size, width: 720 });
    setAiOpen(true);
  }

  const handleImageToggle = () => {
    setIsOpen(!isOpen);
  }

  const handleDragEnter = () => {
    setIsOpen(true);
  };


  const handleDragOver = (event) => {
    event.preventDefault();
    setIsOpen(true);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsOpen(true);
  };

  return (
    <div className="w-[78px] h-[100%] px-[12px] py-[16px] bg-white border-r border-gray-10 flex-col justify-start items-center gap-[18px] inline-flex z-100">
      <Tooltip position="right" message="Try a template" margin="-mt-[20px]" className="group">
        <div className="flex-col justify-center items-center gap-[4px] flex cursor-pointer" onClick={handleTemplate}>
          <SVG src={template_icon} fill="#06182F" alt="Template icon" className='transform transition-transform group-hover:-translate-y-[2px]' />
          <div className="text-center text-gray-04 text-xs font-medium leading-[18px]">Template</div>
        </div>
      </Tooltip>
      <AskAIButton>
        <Tooltip position="right" message="Play with AI" margin="-mt-[16px] ml-[12px]" className="group">
          <div className="flex-col justify-center items-center gap-[4px] flex mt-[2px] ">
            <SVG src={ai_icon} fill="#06182F" alt="Ask AI icon" className='w-[20px] h-[20px] transform transition-transform group-hover:-translate-y-[2px]' />
            <div className="text-center text-gray-04 text-xs font-medium leading-[18px]">Ask AI</div>
          </div>
        </Tooltip>
      </AskAIButton>
      <Tooltip position="right" message="Image to Code" margin="-mt-[16px] ml-[12px]" className="group">
        <div
          className={`group flex-col justify-center items-center gap-[4px] flex cursor-pointer`}
          onClick={handleImageToggle}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <img src={image} alt="Image icon" className='transform transition-transform group-hover:-translate-y-[2px]' />
          <div className="text-center text-gray-04 text-xs font-medium leading-[18px]">Image</div>
        </div>
      </Tooltip>
      <Tooltip position="right" message="Clear the canvas" margin="-mt-[16px] ml-[12px]" className="group">
        <div
          className="flex-col justify-center items-center gap-[4px] flex cursor-pointer"
          onClick={handleOpenModal}
        >
          <img src={clear} alt="Clear icon" className='transform transition-transform group-hover:-translate-y-[2px]' />
          <div className="text-center text-gray-04 text-xs font-medium leading-[18px]">Clear</div>
        </div>
      </Tooltip>
    </div>
  );
};
