import React, { useState } from "react";
import { GeneratedSnapshot, OriginalSnapshot } from "../SnapshotDisplay";
import generate from '../../img/generate-white.svg';
import regenerate from '../../img/regenerate.svg';
import { SVG } from "../SVG";
import { useAi, useSnapshot } from "../../hooks";
import { Button } from "../Button";

export const Result = () => {
    const { setResult, setMessage, message, originalIndex, handleGenerate, handleRemoveAllFiles } = useAi();
    const { navigateHistory } = useSnapshot();
    const [selectedSnapshot, setSelectedSnapshot] = useState('Generated');

    const handleSelect = (value) => {
        setSelectedSnapshot(value);
    };

    const handleRegenerate = () => {
        setMessage(message);
        setResult(false);
        handleGenerate();
    }

    const handleAskModification = () => {
        setMessage('');
        setResult(false);
    }


    return (
        <>
            <div className="flex gap-[8px] w-[460px]">
                <OriginalSnapshot handleSelect={handleSelect} selectedSnapshot={selectedSnapshot}/>
                <GeneratedSnapshot handleSelect={handleSelect} selectedSnapshot={selectedSnapshot} />
            </div>

            <div className="flex flex-row justify-between mt-4 w-full">
                <Button label="Regenerate" onClick={handleRegenerate} icon={regenerate} className="rounded" />
                <button
                    onClick={handleAskModification}
                    className="flex flex-row gap-2 items-center justify-center h-[40px] text-white text-sm px-4 py-2 rounded font-semibold bg-main-orange-01"
                >
                    <SVG src={generate} alt="Generate" fill="#FFFFFF" width="18px" /> Ask modification
                </button>
            </div>
        </>
    )
};
