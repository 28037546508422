import React from 'react';

const Radio = ({ handleSelect, selectedSnapshot, value }) => {
    return (
        <div
            className={`absolute w-6 h-6 rounded border-2 cursor-pointer flex items-center justify-center
            ${value === selectedSnapshot ? 'bg-white border-primary' : 'bg-white border-gray-300'} z-100`}
            onClick={()=> handleSelect(value)}
            style={{ top: '10px', left: '10px' }}
        >
            {value === selectedSnapshot && <div className="w-3 h-3 bg-primary rounded"></div>}
        </div>
    );
};
export default Radio;
