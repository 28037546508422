export function hexToRgbArray(hex) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
        return [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)];
    }
    return null;
}

export function createShade(rgbArray, shadeFactor) {
    if (shadeFactor === 0) return rgbArray;
    return shadeFactor > 0 ? makeRgbArrayLighter(rgbArray, shadeFactor) : makeRgbArrayDarker(rgbArray, -shadeFactor);
}

export function makeRgbArrayLighter([r, g, b], factor) {
    return [Math.round(r + (255 - r) * factor), Math.round(g + (255 - g) * factor), Math.round(b + (255 - b) * factor)];
}

export function makeRgbArrayDarker([r, g, b], factor) {
    return [Math.round(r * (1 - factor)), Math.round(g * (1 - factor)), Math.round(b * (1 - factor))];
}

export function rgbArrayToHex([r, g, b]) {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
}

export function rgbArrayToCssString([r, g, b]) {
    return `rgb(${r}, ${g}, ${b})`;
}

export function rgbArrayToObject1([r, g, b]) {
    return { r: r / 255, g: g / 255, b: b / 255 };
}

function luminance(r, g, b) {
    var a = [r, g, b].map(function (v) {
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

function calculateRatio(rgb1, rgb2) {
    var lum1 = luminance(rgb1[0], rgb1[1], rgb1[2]);
    var lum2 = luminance(rgb2[0], rgb2[1], rgb2[2]);
    var brightest = Math.max(lum1, lum2);
    var darkest = Math.min(lum1, lum2);
    return (brightest + 0.05) / (darkest + 0.05);
}

export function getWhiteTextContrastRatio(color) {
    return parseFloat(calculateRatio(color, [255, 255, 255])).toFixed(2);
}

export function getDarkTextContrastRatio(color) {
    return parseFloat(calculateRatio(color, [0, 0, 0])).toFixed(2);
}
