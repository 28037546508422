export const suggestions = {
  "suggestions": [
    {
      "label": "Check Presence",
      "description": "Add a card component with a shadow, 375px width, and rounded corners. Include a title 'BMW Template Example'. Add three rows for the date, time, and location (in the year 2024). Also include participant details (name, email and phone) with icons. Center a QR code image at the bottom."
    },
    {
      "label": "Presence List",
      "description": "Add a 1000px wide card component with shadow, rounded corners, and a neutral background. Include a title 'Event participants (172)'. Add a section with 'Check presence' and 'Delete' buttons, a search input with a search icon, and a 'Filter' button. Below, include a table with 10 participants details (full name, email, company, phone number, attendance status) and pagination after. Each participant row should have an attendance status with appropriate color (gray for upcoming, blue for present, black for absent) and an options icon."
    },
    {
      "label": "Delete Info",
      "description": "Add a card component with a shadow, 400px width, rounded corners, and a neutral background. Include a header with the title 'Want to delete Ronald Richards?' and a close button. Below, add a message explaining that all related data will be permanently removed upon confirmation. At the bottom, include 'Cancel' and 'Delete' buttons, styled accordingly."
    },
    {
      "label": "Dashboard",
      "description": "Add a 1000px wide card component with shadow, rounded corners, and a neutral background. Include a 'Dashboard' title, four stats cards (participants, events planned, events happened, dealerships) with icons and counts, a table of recent participants, and upcoming events with details and icons."
    },
    {
      "label": "Registration",
      "description": "Add a 400px wide card component with shadow, rounded corners, and a neutral background. Include a title 'Register to this event', a form with fields for full name, email, phone number, and a 'Submit' button. Style the inputs and button accordingly."
    },
    {
      "label": "Schedule",
      "description": "Add a 800px wide card component with shadow, rounded corners, and a neutral background. Include a title 'Event Schedule'. Add a timeline with event details such as time, description, and speaker names. Style the timeline to be visually appealing with icons and colored segments."
    },
    {
      "label": "Profile",
      "description": "Add a 600px wide card component with shadow, rounded corners, and a neutral background. Include a profile picture, participant's name, contact details, company, and bio. Add buttons for 'Edit Profile' and 'Send Message'."
    },
    {
      "label": "Feedback",
      "description": "Add a 500px wide card component with shadow, rounded corners, and a neutral background. Include a title 'Event Feedback'. Add a form with fields for name, email, rating (stars), and comments. Style the submit button accordingly."
    },
    {
      "label": "Gallery",
      "description": "Add a 1000px wide card component with shadow, rounded corners, and a neutral background. Include a title 'Event Gallery'. Add a grid layout to display event photos with captions. Include a lightbox feature for viewing larger images."
    },
    {
      "label": "Announcements",
      "description": "Add a 800px wide card component with shadow, rounded corners, and a neutral background. Include a title 'Announcements'. Add a section for latest announcements with date, title, and a short description. Style each announcement item to be visually distinct."
    }
  ]
}
