import rocket_cat from "../../img/rocket_cat.svg"
import bg_login from "../../img/bg-login.png"
import { Login } from "./Login"
import { useApp } from "../../hooks";

export const LoginModal = () => {
    const { setModal, setIsModalOpen } = useApp();

    const handleCancel = () => {
        setModal(null);
        setIsModalOpen(false);
    }

    return (
        <div className="flex">
            <div className="flex flex-col justify-center">
                <div className="w-[424px] px-[32px] py-[24px]">
                    <Login handleCancel={handleCancel}/>
                </div>
            </div>
            <div
                className="w-[336px] h-[420px] flex flex-col justify-center items-center text-white rounded-r-[16px] ml-[16px] "
                style={{ backgroundImage: `url(${bg_login})` }}
            >
                <h2 className="font-[700] text-[28px] mb-[24px] text-center">Build the web <br /> with plugins</h2>
                <img src={rocket_cat} alt="Cat Astronaut" />
            </div>
        </div>
    )
}

