// hooks/index.js 
import { useContext } from 'react';
import { ThemeContext } from '../contexts/ThemeContext';
import { AiContext } from '../contexts/AiContext';
import { CodeContext } from '../contexts/CodeContext';
import { SnapshotContext } from '../contexts/SnapshotContext';
import { ImageContext } from '../contexts/ImageContext';
import { AppContext } from '../contexts/AppContext';
import { UserContext } from '../contexts/UserContext';
import { SnippetContext } from '../contexts/SnippetContext';
import { CustomizationContext } from '../contexts/CustomizationContext';

export const useTheme = () => useContext(ThemeContext);
export const useCode = () => useContext(CodeContext);
export const useAi = () => useContext(AiContext);
export const useSnapshot = () => useContext(SnapshotContext);
export const useImage = () => useContext(ImageContext);
export const useApp = () => useContext(AppContext)
export const useUser = () => useContext(UserContext);
export const useSnippet = () => useContext(SnippetContext);
export const useCustomization = () => useContext(CustomizationContext);
