// src/contexts/AiContext.js
import React, { createContext, useState, useCallback, useEffect } from 'react';
import { useApp, useCode, useCustomization, useSnapshot, useUser } from '../hooks';

import errorImage from "../img/image-type.svg";
import regenerate from "../img/regenerate_white.svg";

import { Button } from '../components/Button';

import packageJson from "../../package.json";

export const AiContext = createContext();

export const AiProvider = ({ children }) => {
    const { messages } = useCustomization();
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [message, setMessage] = useState("");
    const [result, setResult] = useState(false);
    const [originalIndex, setOriginalIndex] = useState();
    const [generatedIndex, setGeneratedIndex] = useState();
    const [error, setError] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('Generating your code...')

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [previews, setPreviews] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState([]);
    const [hash, setHash] = useState();
    const [intervalId, setIntervalId] = useState(null);

    const { code, setCode, setCodeAuthor, codeStatus, latestHash, setLatestHash } = useCode();
    const { setModal, setIsModalOpen } = useApp();
    const { forceTakeSnapshot, setSnapDescription } = useSnapshot();
    const { REACT_APP_SERVER } = process.env;

    const { user, userIp } = useUser();

    const handleImageFormatError = () => {
        setModal({
            size: 'large',
            content:
                <div className='w-[256px] mx-auto flex flex-col justify-center items-center text-center'>
                    <img src={errorImage} />
                    <div className='py-[24px]'>
                        {messages.error.unsuported_image_format}
                    </div>
                    <Button primary={true} label="Try again" icon={regenerate} className="rounded" onClick={() => setIsModalOpen(false)} />
                </div>
        })
        setIsModalOpen(true);
    }

    const isValidFileType = (file) => {
        const validTypes = ['image/jpeg', 'image/png', 'image/heic'];
        return validTypes.includes(file.type);
    };

    function changeLoadingMessage(message, time) {
        const messageTimeoutId = setTimeout(() => {
            setLoadingMessage(message);
        }, time);
        setIntervalId(messageTimeoutId)
        return () => clearTimeout(messageTimeoutId);
    }

    function loadingMessages() {
        const messages = ['Generating your code...', 'Hold tight...', 'Almost there...', 'Just a moment more...'];
        const timeIntervals = [0, 5000, 10000, 15000];
        const clearFunctions = [];

        messages.forEach((msg, index) => {
            const clearFn = changeLoadingMessage(msg, timeIntervals[index]);
            clearFunctions.push(clearFn);
        });

        const clearAll = () => {
            clearFunctions.forEach(clearFn => clearFn());
        };

        return clearAll;
    }

    const handleGenerate = useCallback(async () => {
        setLoadingMessage('Generating your code...');
        setIsLoading(true);
        setOriginalIndex(null);
        setGeneratedIndex(null);
        const formData = new FormData();
        formData.append('message', message);
        formData.append('code', code);
        formData.append('version', packageJson.version);
        formData.append('user', JSON.stringify(user));
        formData.append('userIp', userIp);
        selectedFiles.forEach((file) => {
            formData.append('images', file);
        });

        try {
            const response = await fetch(`${REACT_APP_SERVER}/request/initiate`, {
                method: 'POST',
                body: formData,
            });
            const originalIndex = await forceTakeSnapshot('Original');
            setOriginalIndex(originalIndex);

            let data;
            try {
                data = await response.json();
            } catch (error) {
                setError(true);
                setSelectedFiles([]);
                setPreviews([]);
                setLoadingStatus([]);
                setSnapDescription(null);
                setMessage(message);
                setIsLoading(false);
                clearInterval(intervalId);
            }
            if (response.ok) {
                const clearLoadingMessages = loadingMessages();
                const { hash } = data;
                setLatestHash(hash);
                let timeoutId;

                const intervalId = setInterval(async () => {
                    const statusResponse = await fetch(`${REACT_APP_SERVER}/request/status/${hash}`);
                    let statusData;

                    try {
                        statusData = await statusResponse.json();
                    } catch (error) {
                        clearTimeout(timeoutId);
                        clearInterval(intervalId);
                        clearLoadingMessages();
                        handleRequestError();
                        return;
                    }

                    if (statusData.status === 'completed') {
                        clearTimeout(timeoutId);
                        clearInterval(intervalId);
                        clearLoadingMessages();
                        setHash(hash);
                        setSnapDescription(null);
                        setMessage(message);
                        setCode(statusData.code);
                        setCodeAuthor('AI');
                        setIsLoading(false);
                        setResult(true);
                    } else if (statusData.status === 'error' || statusResponse.status !== 200) {
                        clearTimeout(timeoutId);
                        clearInterval(intervalId);
                        clearLoadingMessages();
                        handleRequestError();
                    }
                }, 1000);

                timeoutId = setTimeout(() => {
                    clearInterval(intervalId);
                    clearLoadingMessages();
                    handleRequestError();
                    console.warn("Request timeout");
                }, 120000);

                setIntervalId(intervalId);
            }
            else {
                setError(true);
                console.error('Error initiating request:', data.message);
                setIsLoading(false);
            }
            function handleRequestError() {
                setError(true);
                setSelectedFiles([]);
                setPreviews([]);
                setLoadingStatus([]);
                setSnapDescription(null);
                setMessage(message);
                setIsLoading(false);
            }
        } catch (error) {
            setError(true);
            console.error('Error initiating request:', error);
            setIsLoading(false);
        }
    }, [message, code, selectedFiles, setIsLoading, setMessage, setResult, setCode, setCodeAuthor, setSnapDescription, setSelectedFiles, setPreviews, setLoadingStatus]);

    const handleStopGeneration = useCallback(() => {
        if (intervalId) {
            clearInterval(intervalId);
            setIsLoading(false);
        }
    }, [intervalId]);

    useEffect(() => {
        if (!latestHash || !code) return;

        let attempts = 0;
        const maxAttempts = 10;

        const codeRenderIntervalId = setInterval(() => {
            if (codeStatus === 'rendered' && latestHash === hash) {
                forceTakeSnapshot('Generated').then(generatedIndex => {
                    setHash(null);
                    setGeneratedIndex(generatedIndex);
                    clearInterval(codeRenderIntervalId);
                });
            }

            attempts += 1;
            if (attempts >= maxAttempts) {
                clearInterval(codeRenderIntervalId);
            }
        }, 1000);

        return () => clearInterval(codeRenderIntervalId);
    }, [codeStatus, latestHash, code, hash]);

    const handleInputChange = (e) => {
        setMessage(e.target.value);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const files = Array.from(e.dataTransfer.files).filter(isValidFileType);
        if (files.length > 0) {
            setSelectedFiles((prev) => [...prev, ...files]);
            const newPreviews = files.map((file) => URL.createObjectURL(file));
            setPreviews((prev) => [...prev, ...newPreviews]);
            setLoadingStatus((prev) => [...prev, ...files.map(() => true)]);
        } else {
            handleImageFormatError();
        }
        setIsDragging(false);
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files).filter(isValidFileType);
        if (files.length > 0) {
            setSelectedFiles((prev) => [...prev, ...files]);
            const newPreviews = files.map((file) => URL.createObjectURL(file));
            setPreviews((prev) => [...prev, ...newPreviews]);
            setLoadingStatus((prev) => [...prev, ...files.map(() => true)]);
        } else {
            handleImageFormatError();
        }
    };

    const handleRemoveFile = (index) => {
        const newSelectedFiles = selectedFiles.filter((_, i) => i !== index);
        setSelectedFiles(newSelectedFiles);
        const newPreviews = previews.filter((_, i) => i !== index);
        setPreviews(newPreviews);
        const newLoadingStatus = loadingStatus.filter((_, i) => i !== index);
        setLoadingStatus(newLoadingStatus);

        document.querySelector('#ai-request-images').value = "";
    };

    const handleRemoveAllFiles = () => {
        setSelectedFiles([]);
        setPreviews([]);
        setLoadingStatus([]);
    };

    return (
        <AiContext.Provider value={{
            isOpen, setIsOpen,
            isLoading, setIsLoading,
            isDragging, setIsDragging,
            message, setMessage,
            result, setResult,
            originalIndex, setOriginalIndex,
            generatedIndex, setGeneratedIndex,
            error, setError,

            previews,

            loadingStatus, setLoadingStatus,

            handleGenerate,
            handleStopGeneration,
            handleInputChange,

            handleDrop,
            handleFileChange,
            handleRemoveFile,
            handleRemoveAllFiles,
            loadingMessage
        }}>
            {children}
        </AiContext.Provider>
    );
};
