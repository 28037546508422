import React, { useEffect, useState } from 'react';
import { useSnapshot, useTheme, useCode, useAi } from '../hooks';
import { Spinner } from "./Spinner";
import Radio from './Radio';

const SnapshotComponent = ({ index, label, selectedSnapshot, handleSelect }) => {
    const [snapshot, setSnapshot] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { getSnapshotById, snapPreviewIsLoading, navigateHistory } = useSnapshot();

    useEffect(() => {
        if (index) {
            setIsLoading(true);
            const fetchSnapshot = async () => {
                const fetchedSnapshot = await getSnapshotById(index);
                setSnapshot(fetchedSnapshot);
                setIsLoading(false);
            };
            fetchSnapshot();
        } else {
            setSnapshot(null);
        }
    }, [index, getSnapshotById]);

    const handleClick = () => {
        navigateHistory(index);
        handleSelect(label);
    }

    return (
        <div
            className={`border ${selectedSnapshot === label ? 'border-main-orange-05' : 'border-gray-100'} rounded bg-white cursor-pointer w-[226px] h-[226px] flex flex-col items-start justify-start`}
            onClick={handleClick}
        >
            <div className="relative w-full rounded">
            <Radio handleSelect={handleSelect} selectedSnapshot={selectedSnapshot} value={label} />
                <div className="bg-gray-100 rounded-t p-4 h-[176px] w-full bg-code-1 flex items-center justify-center">
                    <div className="flex overflow-hidden justify-center items-center h-full text-gray-400 rounded-top">
                        {isLoading || snapPreviewIsLoading ? (
                            <Spinner />
                        ) : snapshot?.image?.startsWith("data:,") ? (
                            <span></span>
                        ) : snapshot?.image ? (
                            <img
                                src={snapshot.image}
                                alt="Snapshot"
                                className="max-h-full max-w-full"
                            />
                        ) : (
                            <Spinner />
                        )}
                    </div>
                </div>
                <div className="w-full text-center mt-[10px] font-semibold text-gray-700">
                    {label || snapshot?.snapDescription || index}
                </div>
            </div>
        </div>

    );
};

export const OriginalSnapshot = ({ selectedSnapshot, handleSelect }) => {
    const { originalIndex } = useAi();
    return <SnapshotComponent index={originalIndex} label="Original" hardReset={true} handleSelect={handleSelect} selectedSnapshot={selectedSnapshot} />;
};

export const GeneratedSnapshot = ({ selectedSnapshot, handleSelect }) => {
    const { generatedIndex } = useAi();
    return <SnapshotComponent index={generatedIndex} label="Generated" hardReset={true} handleSelect={handleSelect} selectedSnapshot={selectedSnapshot} />;
};

export const SnapshotDisplay = ({ id, label }) => {
    return <SnapshotComponent index={id} label={label} />;
};

export const SnapshotLast = ({ nth, label }) => {
    const { getNthLastSnapshotId, storeVersion } = useSnapshot();
    const [id, setId] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSnapshot = async () => {
            const newId = await getNthLastSnapshotId(nth);
            setId(newId);
            setLoading(false);
        };

        fetchSnapshot();
    }, [nth, storeVersion]);

    if (loading) {
        return <div>Loading...</div>;
    } else if (id) {
        return <SnapshotDisplay id={id} label={label} />;
    } else {
        return null;
    }
};
