// src/components/AskAI/Request.js
import React, { useEffect, useRef, useState } from "react";
import { useAi, useCustomization } from "../../hooks";
import { SVG } from "../SVG";
import clips from '../../img/icons/clips.svg';
import stop from '../../img/stop.svg';
import files from '../../img/icons/files.svg';
import close from '../../img/icons/close.svg';
import generate from '../../img/generate-white.svg';
import { Spinner } from "../Spinner";
import { Button } from "../Button";

const GenerateButton = ({ disabled, onClick }) => {
    return (
        <button
            className={`flex flex-row w-full gap-2 items-center justify-center h-[40px] text-white text-sm px-4 py-2 rounded font-semibold ${disabled ? 'bg-main-orange-06' : 'bg-main-orange-01'}`}
            onClick={onClick}
            disabled={disabled}
        >
            <SVG src={generate} alt="Generate" fill="#FFFFFF" width="18px" /> Generate
        </button>
    )
}

export const Request = () => {
    const { suggestions } = useCustomization();

    const {
        isLoading, message, setMessage, previews, loadingStatus, setLoadingStatus, handleGenerate, handleInputChange, handleDrop, handleFileChange, handleRemoveFile,
        setOriginalIndex, setGeneratedIndex, handleStopGeneration, isDragging, setIsDragging, loadingMessage
    } = useAi();
    const fileInputRef = useRef(null);

    useEffect(() => {
        setOriginalIndex(null);
        setGeneratedIndex(null);
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter' && message.trim() !== '') {
                handleGenerate();
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [message, handleGenerate]);

    const handleAttach = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const [selectedLabels, setSelectedLabels] = useState([]);

    useEffect(() => {
        function getRandomElements(arr, count) {
            let shuffled = arr.slice().sort(() => 0.5 - Math.random());
            return shuffled.slice(0, count);
        }
        const labels = getRandomElements(suggestions.suggestions, 3);
        setSelectedLabels(labels);
    }, []);

    const handleLabelClick = (description) => {
        setMessage(description);
    };

    return (
        <>
            <div
                className={`rounded mb-2 w-[366px] p-[10px] ${isDragging ? 'bg-gray-11' : 'border'}`}
                onDrop={handleDrop}
                onDragOver={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsDragging(true);
                }}
                onDragLeave={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsDragging(false);
                }}
            >
                {previews.length > 0 && (
                    <div className="flex flex-row gap-[12px] flex-wrap">
                        {previews.map((preview, index) => (
                            <div key={index} className="group w-[48px] h-[48px] rounded-[8px] mb-[12px] relative flex items-center justify-center">
                                <img
                                    src={preview}
                                    className="absolute top-0 left-0 w-full h-full rounded-[8px]"
                                    alt={`preview-${index}`}
                                    onLoad={() => {
                                        const newLoadingStatus = [...loadingStatus];
                                        newLoadingStatus[index] = false;
                                        setLoadingStatus(newLoadingStatus);
                                    }}
                                />
                                <div className="absolute top-0 left-0 w-full h-full bg-[#0F222E50] rounded-[8px]"></div>
                                {loadingStatus[index] && <Spinner />}
                                <div
                                    className="absolute bg-gray-10 -top-1 -right-1 w-4 h-4 cursor-pointer rounded flex justify-center items-center opacity-0 group-hover:opacity-100"
                                    alt="Remove"
                                    onClick={() => handleRemoveFile(index)}
                                >
                                    <img src={close} width={14} />
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {isLoading ?
                    <div className="flex justify-center items-center h-[60px]">
                        <Spinner alt="Loading" className="mr-2" />
                        <div className="text-[#333333]">{loadingMessage}</div>
                    </div>
                    :
                    isDragging ?
                        <div className="h-[84px] flex flex-col items-center justify-center h-full text-gray-05 pointer-events-none">
                            <img src={files} />
                            <div className="mt-[6px]">
                                Drop your files here...
                            </div>
                        </div>
                        :
                        <div className="flex flex-row items-start">
                            <img src={clips} className="w-[20px] h-[20px] mr-[6px] cursor-pointer"
                                onClick={handleAttach}
                            />
                            <textarea
                                className="w-full h-[60px] border-none focus:outline-none resize-none placeholder-gray-6"
                                onInput={handleInputChange}
                                disabled={isLoading || isDragging}
                                placeholder="Describe what you want to change in the current component"
                                value={message}
                                onChange={(event) => setMessage(event.target.value)}
                            />
                        </div>
                }

            </div>
            <div className="gap-[8px] flex flex-row">
                {selectedLabels.map((suggestion, index) => (
                    <div
                        key={index}
                        className={`border rounded-[12px] max-w-[111px] h-[26px] px-[8px] flex items-center justify-start overflow-hidden w-auto 
                        ${isLoading ? "hidden" : "cursor-pointer hover:bg-gray-11 hover:border hover:border-gray-10 mb-[8px]"}`}
                        onClick={isLoading ? null : () => handleLabelClick(suggestion.description)}
                    >
                        <span className="text-[12px] truncate">{suggestion.label}</span>
                    </div>
                ))}
            </div>
            <div className="flex flex-row mt-[8px] w-full">
                {isLoading ?
                    <Button onClick={handleStopGeneration} icon={stop} label="Stop generation" className="rounded w-full" />
                    :
                    <GenerateButton onClick={handleGenerate} disabled={message === '' && previews.length === 0} />
                }

            </div>
            <input
                id="ai-request-images"
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
                multiple
            />
        </>
    );
};
