import React, { useCallback, useEffect, useState } from "react";
import { useCustomization, useUser } from "../../hooks";
import githubIcon from "../../img/github_black.svg";
import googleIcon from "../../img/google.svg";
import { Button } from "../Button";
import { Verify } from "./Verify.js";

const { REACT_APP_SERVER, REACT_APP_CLIENT } = process.env;

export const Login = ({ handleCancel }) => {
    const { messages } = useCustomization();
    const { loginError } = useUser();
    const { user } = useUser();
    const [email, setEmail] = useState("");
    const [showVerifyEmail, setShowVerifyEmail] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        setError(loginError);
    }, [loginError]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const error = params.get('error');
        if (error) {
            if (error === 'permissions') {
                setError(messages.login.permissions_error);
            } else {
                setError(messages.login.unkown_error);
            }
        }
    }, [setError]);

    const handleLoginWithGithub = useCallback(() => {
        if (user?.id) {
            const state = encodeURIComponent(`${user.id}|${REACT_APP_CLIENT}`);
            const githubAuthUrl = `https://github.com/login/oauth/authorize?client_id=Iv23liQHVHnJU22O7PRd&scope=user:email&state=${state}`;
            window.location.href = githubAuthUrl;
        }
    }, [user]);

    const handleLoginWithGoogle = useCallback(() => {
        if (user?.id) {
            const state = encodeURIComponent(`${user.id}|${REACT_APP_CLIENT}`);
            const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=709915055434-cr2l5dh0665edgilvr0qdrm7c215np32.apps.googleusercontent.com&redirect_uri=${REACT_APP_SERVER}/auth/google/callback&response_type=code&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/plus.login https://www.googleapis.com/auth/userinfo.profile&state=${state}`;
            window.location.href = googleAuthUrl;
        }
    }, [user]);

    const verifySent = () => {
        fetch(`${REACT_APP_SERVER}/auth/send-code`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: email })
        })
            .then(response => response.json())
            .then(data => {
                if (data.status == 'sent') {
                    setShowVerifyEmail(true);
                } else {
                    setError(messages.login.unkown_error);
                    console.error('Failed to send code:', data.message);
                }
            })
            .catch(error => {
                setError(messages.login.unkown_error);
                console.error('Error:', error);
            });
    };
    const handleLoginWithEmail = () => {
        if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            setShowVerifyEmail(true);
            verifySent();
        } else {
            document.getElementById('email').focus();
            setError('Oops, this email looks invalid. Check and try again.')
        }
    };

    const handleCancelVerify = () => {
        if (handleCancel) {
            handleCancel()
            return
        }
        setShowVerifyEmail(false);
    }

    return (
        <div className="w-full">
            {showVerifyEmail ? (
                <Verify email={email} handleCancelVerify={handleCancelVerify} />
            ) : (
                <>
                    <p className="font-semibold text-[18px] text-gray-02 mb-[6px]">{messages.login.title}</p>
                    <p className="font-regular mb-[24px] text-gray-05">{messages.login.paragraph}</p>
                    <div className="flex mb-[20px] justify-between">
                        <Button
                            icon={googleIcon}
                            onClick={handleLoginWithGoogle}
                            className="rounded w-[172px]"
                            label="Google"
                        />
                        <Button
                            icon={githubIcon}
                            onClick={handleLoginWithGithub}
                            className="rounded w-[172px]"
                            label="Github"
                        />
                    </div>
                    <div className="flex items-center mb-[12px]">
                        <hr className="flex-1" />
                        <span className="px-[8px] text-gray-600 text-[12px]">OR</span>
                        <hr className="flex-1" />
                    </div>
                    <div>
                        <label htmlFor="email" className="block mb-[4px] text-[13px]">Email</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className={`w-full border rounded px-[12px] h-[40px] ${error ? 'border-red-300' : 'border-gray-300'}`}
                            placeholder="Type your email"
                        />
                    </div>
                    {error && <div className="mt-[4px] text-[#F14A3E]">*{error}</div>}
                    <Button primary={true} className="w-full rounded mt-[32px] mb-[12px]" label="Log in" onClick={handleLoginWithEmail} />
                    {handleCancel &&
                        <Button plain={true} className="w-full rounded" label={
                            <div className="flex flex-row items-center justify-center text-main-orange-01">
                                Continue as a guest
                                <span className="material-symbols-outlined ml-2">arrow_forward</span>
                            </div>
                        }
                            onClick={handleCancel}
                        />
                    }
                </>
            )}
        </div>
    )
}