import React from "react";
import mobileWarning from "../img/mobile-warning.svg";
import logo from "../img/logo/logo_full.svg";
import github_icon from "../img/github_black.svg";
import discord_icon from "../img/discord.svg";
import { Button } from "./Button";


const MobileWarning = () => {
    return (
        <div className="flex flex-col h-screen bg-gray-100 ">
            <div className="py-4">
                <a href="https://webcrumbs.org">
                    <img src={logo} alt="Logo" className="w-[149px] h-[24px] p-0 m-0 mb-[90px]" />
                </a>
                <div className="flex flex-col flex-1 items-center justify-center w-full px-4 pr-8 pl-8">
                    <img src={mobileWarning} alt="Mobile Warning" className="p-0 m-0 mb-[32px]" />
                    <div className="pr-8 pl-8">
                        <p className="mt-8 text-base text-center text-base text-gray-03">
                            We're excited to help you code!
                            Our mobile version is still brewing, but full experience is ready on desktop.
                            Please try it on your laptop or desktop.
                            Happy coding!
                        </p>
                        <p className="mt-8 text-base text-center text-base text-gray-03 mb-3">
                            Please follow us: <a href="https://webcrumbs.org" target="_blank" className="underline">Webcrumbs</a>
                        </p>
                    </div>
                    <div className="flex items-center gap-3">
                        <Button icon={github_icon} onClick={() => window.open("https://github.com/webcrumbs-community/webcrumbs", "_blank")} className="rounded" />
                        <Button icon={discord_icon} onClick={() => window.open("https://discord.com/invite/ZCj5hFv8xV", "_blank")} className="rounded" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobileWarning;
