export const colorProfiles = {
    webcrumbs: {
        primary: [
            { tokenSuffix: "DEFAULT", "brightness": 0 },
            { tokenSuffix: "50", "brightness": 0.65 },
            { tokenSuffix: "100", "brightness": 0.55 },
            { tokenSuffix: "200", "brightness": 0.4 },
            { tokenSuffix: "300", "brightness": 0.25 },
            { tokenSuffix: "400", "brightness": 0.1 },
            { tokenSuffix: "500", "brightness": 0 },
            { tokenSuffix: "600", "brightness": -0.1 },
            { tokenSuffix: "700", "brightness": -0.25 },
            { tokenSuffix: "800", "brightness": -0.4 },
            { tokenSuffix: "900", "brightness": -0.55 }
        ],
        neutral: [
            { tokenSuffix: "DEFAULT", brightness: 0 },
            { tokenSuffix: "50", brightness: 0 },
            { tokenSuffix: "100", brightness: -0.03 },
            { tokenSuffix: "200", brightness: -0.06 },
            { tokenSuffix: "300", brightness: -0.09 },
            { tokenSuffix: "400", brightness: -0.12 },
            { tokenSuffix: "500", brightness: -0.15 },
            { tokenSuffix: "600", brightness: -0.18 },
            { tokenSuffix: "700", brightness: -0.21 },
            { tokenSuffix: "800", brightness: -0.24 },
            { tokenSuffix: "900", brightness: -0.27 }
        ]
    }
}