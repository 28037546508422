import { useImage } from "../../hooks";

export const ImageButton = ({ children }) => {
    const { setIsOpen, isOpen } = useImage();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div onClick={toggleMenu} >
            {children}
        </div>
    )
}