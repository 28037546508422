import React, { useState, useEffect, useRef } from 'react';
import Prism from 'prismjs';
import 'prismjs/components/prism-cshtml';
import './code.css';
import { useCode, useSnapshot, useTheme } from "../../hooks";

export const CodeEditor = () => {
    const { code, setCode, setCodeAuthor } = useCode();
    const { setThemeStatus } = useTheme();
    const { setSnapDescription } = useSnapshot();
    const preRef = useRef(null);
    const textAreaRef = useRef(null);
    const containerRef = useRef(null);

    useEffect(() => {
        Prism.highlightAll();
    }, [code]);

    useEffect(() => {
        const adjustHeight = () => {
            const preHeight = preRef.current.scrollHeight;
            textAreaRef.current.style.height = `${preHeight}px`;
            preRef.current.style.height = `${preHeight}px`;
        };

        adjustHeight();
        window.addEventListener('resize', adjustHeight);

        return () => {
            window.removeEventListener('resize', adjustHeight);
        };
    }, [code]);

    const handleChangeCode = (event) => {
        setCode(event.target.value);
        setCodeAuthor('Human');
        setThemeStatus('rendered');
        setSnapDescription('Changed code manually');
    };

    return (
        <div
            className="w-full flex-grow relative bg-code-01 overflow-auto"
            ref={containerRef}
            style={{ height: '100%', overflowY: 'auto', scrollBehavior: 'smooth' }}
        >
            <pre
                ref={preRef}
                className="absolute inset-0 w-full flex-grow p-[24px] pt-[12px] font-mono focus:outline-none whitespace-pre-wrap overflow-visible"
            >
                <code className="language-html text-red ">
                    {code}
                </code>
            </pre>
            <textarea
                ref={textAreaRef}
                value={code}
                spellCheck={false}
                className="absolute inset-0 w-full flex-grow p-[24px] pt-[12px] caret-white text-transparent font-mono focus:outline-none bg-transparent overflow-hidden"
                placeholder="JSX"
                style={{ resize: 'none' }}
                onChange={handleChangeCode}
            />
        </div>
    );
}
