import { useCustomization } from "../../hooks"
import { Login } from "./Login"

export const LoginPage = () => {
    const { images } = useCustomization();

    return (
        <div className="bg-neutral-01 flex h-screen w-screen overflow-hidden flex-col justify-center items-center hidden sm:flex">
            <div className="w-[440px] px-[24px] py-[48px] bg-white shadow-lg rounded-lg text-center">
                <div className="scale-110">
                    {images.main_logo_vertical}
                </div>
                <br />
                <Login />
            </div>
        </div>
    )
}