import React, { createContext, useContext, useState, useEffect } from "react";
import { useUserId } from "../hooks/useUserId";
import { useCustomization } from "../hooks";
import { Spinner } from "../components/Spinner";
import { useNavigate } from "react-router-dom";
import { LoginPage } from "../components/Login/LoginPage";

export const UserContext = createContext();

const { REACT_APP_SERVER } = process.env;

export const UserProvider = ({ children }) => {
  const navigate = useNavigate();
  const { guestId, ga4ClientId, clarityUserId } = useUserId();
  const { workspace, permissions, messages } = useCustomization();
  const [hasPermission, setHasPermission] = useState();
  const [user, setUser] = useState({
    id: guestId,
    ga4_client_id: ga4ClientId,
    clarity_user_id: clarityUserId,
  });
  const [token, setToken] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [avatar, setAvatar] = useState();
  const [loginError, setLoginError] = useState();
  const [username, setUsername] = useState();
  const [name, setName] = useState();

  const [userIp, setUserIp] = useState("");

  useEffect(() => {
    const token = localStorage.getItem('tkgw');
    if (token && token !== 'undefined') {
      setToken(token);
    }
  }, [setToken]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token') || null;
    if (token && token !== 'undefined') {
      localStorage.setItem('tkgw', token);
      setToken(token);
      setIsLoggedIn(true);
      navigate('/frontend-ai');
    }
  }, [navigate, setToken]);

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.clear('tkgw');
    setAvatar(null);
    setName(null);
  }

  useEffect(() => {
    if (permissions?.canAccessAsGuest) {
      setHasPermission(true)
    } else {
      if (isLoggedIn) {
        setHasPermission(true)
      } else {
        setHasPermission(false)
      }
    }
  }, [isLoggedIn])

  useEffect(() => {
    const verifyToken = async () => {
      if (token) {
        try {
          const res = await fetch(`${REACT_APP_SERVER}/auth/verify-login`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token, workspace }),
          });
          
          if (!res.ok) {
            setIsLoggedIn(false);
            setLoginError(messages.login.permissions_error)
          } else {
            const data = await res.json();
            const { avatar, name } = data;
            setAvatar(avatar);
            setName(name);
            setIsLoggedIn(true);

            if (typeof window.clarity === 'function') {
              try {
                window.clarity("set", "name", name);
              } catch (error) {
              }
            }

          }
        } catch (err) {
          setIsLoggedIn(false);
        }
      } else {
        setIsLoggedIn(false);
      }
    };

    verifyToken();
  }, [token]);

  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setUserIp(data.ip);
      } catch (error) {
        console.error("Error fetching the IP address:", error);
      }
    };

    fetchIP();
  }, []);

  useEffect(() => {
    setUser((prevUser) => ({
      ...prevUser,
      ga4_client_id: ga4ClientId,
      clarity_user_id: clarityUserId
    }));
  }, [ga4ClientId, clarityUserId]);

  return (
    <UserContext.Provider value={{ user, setUser, userIp, token, setToken, isLoggedIn, handleLogout, avatar, name, permissions, loginError }}>
      {hasPermission === null ?
        <div className="bg-neutral-01 flex h-screen w-screen overflow-hidden flex-col justify-center items-center hidden sm:flex">
          <Spinner />
        </div>
        : hasPermission ?
          children :
          <LoginPage />
      }
    </UserContext.Provider>
  );
};
