import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import { Tool } from './pages/Tool';
// import { Plugin } from './components/FullScreen/Plugin';
// import { Monitor } from './pages/Monitor';

import './output.css';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Navigate to="/frontend-ai" replace />} />
                <Route path="/frontend-ai" element={<Tool />} />
                {/* <Route path="/frontend-ai/view/:hash" element={<Plugin />} /> */}
                {/* <Route path="/monitor" element={<Monitor />} /> */}
            </Routes>
        </Router>
    );
}

export default App;
