import React, { useEffect, useRef, useState } from "react";
import { useCode, useSnapshot, useTheme } from "../hooks";
import { Spinner } from "./Spinner";

import down from '../img/down.svg';

export const FontSelector = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { theme, updateTheme, fontPairing } = useTheme();
    const { setCodeStatus } = useCode();
    const { setSnapDescription } = useSnapshot();
    const dropdownRef = useRef(null);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef, setIsOpen]);

    useEffect(() => {
        // Preload font images
        const preloadImages = () => {
            Object.keys(fontPairing).forEach(font => {
                fontPairing[font].forEach(titleFont => {
                    const defaultImg = new Image();
                    const inverseImg = new Image();
                    const formatFontName = (fontName) => fontName.toLowerCase().replace(/\s+/g, '-');
                    const formattedDefaultFont = formatFontName(font);
                    const formattedTitleFont = formatFontName(titleFont);

                    defaultImg.src = `/img/fonts/${formattedDefaultFont}_${formattedTitleFont}.png`;
                    inverseImg.src = `/img/fonts/${formattedTitleFont}_${formattedDefaultFont}.png`;
                });
            });
        };
        preloadImages();
    }, [fontPairing]);

    const handleFontChange = ([newDefaultFont, newTitleFont]) => {
        updateTheme('fontFamily.baseValue', [newDefaultFont, newTitleFont]);
        setIsOpen(false);
        setSnapDescription('Changed font');
        setCodeStatus('rendered');
    };

    const checkStyleExists = (fonts) => {
        return fonts.every(fontName => document.getElementById(fontName) !== null);
    };

    const formatFontFamily = (fontFamily) => {
        if (!fontFamily) return 'Inter';
        return fontFamily
            .split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    return (
        <div className="mb-4 relative" ref={dropdownRef}>
            <label className="block text-sm font-medium text-gray-01">
                Font pairing
            </label>
            <button
                type='button'
                onClick={() => setIsOpen(!isOpen)}
                className="flex justify-between items-center mt-1 block w-full rounded border border-gray-10 bg-white h-[40px] px-3 text-left sm:text-sm"
            >
                {checkStyleExists(theme.fontFamily?.baseValue) ? (
                    <>
                        {theme.fontFamily?.baseValue[0] !== theme.fontFamily?.baseValue[1] && `${formatFontFamily(theme.fontFamily?.baseValue[1])} / `}
                        {formatFontFamily(theme.fontFamily?.baseValue[0]) || 'Inter'}
                    </>
                ) : (
                    <Spinner />
                )}
                <img src={down} alt="down" />
            </button>
            <div
                className={`absolute z-100 bg-white rounded shadow-lg border border-gray-10 p-4 right-0 overflow-auto ${isOpen ? 'opacity-100' : 'hidden'}`}
                style={{ minWidth: '288px', height: '188px' }}
            >
                <div className="grid grid-cols-2 gap-3">
                    {Object.keys(fontPairing).map((font, index) => (
                        <React.Fragment key={index} >
                            {fontPairing[font].slice(0, 4).map((titleFont, index) => (
                                <div
                                    key={index}
                                    className="h-[44px] border rounded flex flex-col cursor-pointer overflow-hidden"
                                    onClick={() => handleFontChange([font, titleFont])}
                                >
                                    <FontIllustration defaultFont={font} titleFont={titleFont} />
                                </div>
                            ))}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
};

const FontIllustration = ({ defaultFont, titleFont }) => {
    const formatFontName = (fontName) => fontName.toLowerCase().replace(/\s+/g, '-');
    const formattedDefaultFont = formatFontName(defaultFont);
    const formattedTitleFont = formatFontName(titleFont);

    const imageSrc = `/img/fonts/${formattedTitleFont}_${formattedDefaultFont}.png`;

    return (
        <img
            src={imageSrc}
            alt={`${titleFont} with ${defaultFont}`}
            style={{ maxWidth: '100%', height: 'auto' }}
        />
    );
};
