import React, { useState, useEffect } from 'react';
import close from '../../img/icons/close.svg';
import { useAi } from "../../hooks";
import { Request } from "./Request";
import { Result } from "./Result";
import { Error } from "./Error";

export const AIModal = () => {
    const { result, setIsOpen, isOpen, error } = useAi();
    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState({
        x: parseInt(localStorage.getItem('modalX'), 10) || 63,
        y: parseInt(localStorage.getItem('modalY'), 10) || 141
    });
    const [startPos, setStartPos] = useState({ x: 0, y: 0 });
    const [canDrag, setCanDrag] = useState(true);

    useEffect(() => {
        localStorage.setItem('modalX', position.x);
        localStorage.setItem('modalY', position.y);
    }, [position]);

    const handleMouseDown = (event) => {
        if (canDrag) {
            setIsDragging(true);
            setStartPos({
                x: event.clientX - position.x,
                y: event.clientY - position.y
            });
        }
    };

    const handleTouchStart = (event) => {
        if (canDrag) {
            const touch = event.touches[0];
            setIsDragging(true);
            setStartPos({
                x: touch.clientX - position.x,
                y: touch.clientY - position.y
            });
        }
    };

    const handleMouseMove = (event) => {
        if (isDragging) {
            setPosition({
                x: event.clientX - startPos.x,
                y: event.clientY - startPos.y
            });
        }
    };

    const handleTouchMove = (event) => {
        if (isDragging) {
            const touch = event.touches[0];
            setPosition({
                x: touch.clientX - startPos.x,
                y: touch.clientY - startPos.y
            });
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleTouchEnd = () => {
        setIsDragging(false);
    };

    const handleMouseOver = (event) => {
        if (event.target.tagName === 'TEXTAREA') {
            setCanDrag(false);
        } else {
            setCanDrag(true);
        }
    };

    useEffect(() => {
        const handleMouseUpGlobal = () => setIsDragging(false);
        const handleMouseMoveGlobal = (event) => handleMouseMove(event);
        const handleTouchEndGlobal = () => setIsDragging(false);
        const handleTouchMoveGlobal = (event) => handleTouchMove(event);

        document.addEventListener('mousemove', handleMouseMoveGlobal);
        document.addEventListener('mouseup', handleMouseUpGlobal);
        document.addEventListener('touchmove', handleTouchMoveGlobal);
        document.addEventListener('touchend', handleTouchEndGlobal);

        return () => {
            document.removeEventListener('mousemove', handleMouseMoveGlobal);
            document.removeEventListener('mouseup', handleMouseUpGlobal);
            document.removeEventListener('touchmove', handleTouchMoveGlobal);
            document.removeEventListener('touchend', handleTouchEndGlobal);
        };
    }, [isDragging]);

    if (isOpen) {
        return (
            <div
                className="fixed z-300 bg-white rounded shadow-lg border border-gray-10 p-4 cursor-move"
                style={{ left: `${position.x}px`, top: `${position.y}px`, minHeight: '202px', userSelect: canDrag ? 'none' : 'auto' }}
                onMouseDown={handleMouseDown}
                onTouchStart={handleTouchStart}
                onMouseOver={handleMouseOver}
            >
                <div className="flex justify-between pb-2">
                    <div className="text-[16px] font-semibold text-gray-02">AI Assistant</div>
                    <button
                        type="button"
                        onClick={() => setIsOpen(false)}
                    >
                        <img src={close} alt="Close" />
                    </button>
                </div>
                {!result ? (error ? <Error /> : <Request />) : <Result />}
            </div>
        );
    }
};
