import { useApp } from '../../hooks';
import quit from '../../img/quit.svg';
import { Button } from '../Button';


export const Modals = () => {
    const { isModalOpen, setIsModalOpen, modal } = useApp();

    if (isModalOpen) {
        return (
            <div className="fixed inset-0 flex items-center justify-center z-500">
                <div className="fixed inset-0 bg-code opacity-20 z-400"></div>
                <div className={`
                    ${
                        modal?.size === 'large' ? 
                        'w-[722px] h-[411px] flex flex-col items-center justify-center p-[24px] mt-[-48px] mx-[24px]' : 
                        (modal?.size === 'xlarge' ?
                        'w-[900px] h-[676px] flex flex-col items-center justify-center p-[24px] mt-[-48px] mx-[24px]':
                        (modal?.size === 'login' ?
                            'w-[776px] h-[420px]' : 'w-[468px]  p-[24px]'))
                    } 
                    relative bg-white rounded-[16px] shadow-modal z-500
                    `}>
                    <button onClick={modal?.onClose || (() => setIsModalOpen(false))} className="w-[20px] h-[20px] absolute right-[24px] top-[24px]">
                        <img src={quit} alt="quit" />
                    </button>
                    {modal?.content ||
                        <div>
                            {modal?.title &&
                                <div className="text-gray-01 text-[18px] leading-[24px] font-semibold">
                                    {modal.title}
                                </div>
                            }
                            {modal?.text &&
                                <div className="text-gray-05 text-base font-medium leading-normal mt-[8px]">
                                    {modal.text}
                                </div>
                            }
                            {(modal?.cancelText || modal?.confirmText) &&
                                <div className='mt-[20px] flex flex-row justify-between'>
                                    {modal?.cancelText && modal?.onCancel && <Button className="rounded" label={modal.cancelText} onClick={modal.onCancel} />}
                                    {modal?.confirmText && modal?.onConfirm && <Button className="rounded" primary={true} label={modal.confirmText} onClick={modal.onConfirm} />}
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }
}