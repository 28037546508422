// src/contexts/ImageContext.js
import React, { createContext, useState, useCallback, useEffect } from 'react';
import { useCode, useSnapshot, useApp, useAi, useUser, useCustomization } from '../hooks';

import errorImage from "../img/image-type.svg";
import regenerate from "../img/regenerate_white.svg";

import { Button } from '../components/Button';

import packageJson from "../../package.json";

export const ImageContext = createContext();

export const ImageProvider = ({ children }) => {
    const { messages } = useCustomization();
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [result, setResult] = useState(false);
    const [originalIndex, setOriginalIndex] = useState();
    const [generatedIndex, setGeneratedIndex] = useState();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [previews, setPreviews] = useState([]);
    const [hash, setHash] = useState();
    const [intervalId, setIntervalId] = useState(null);

    const { code, setCode, setCodeAuthor, codeStatus, latestHash, setLatestHash, setSize, size } = useCode();
    const { setModal, setIsModalOpen } = useApp();
    const { forceTakeSnapshot, setSnapDescription } = useSnapshot();
    const { setResult: setAiResult, setMessage: setAiMessage } = useAi();
    const { REACT_APP_SERVER } = process.env;

    const { user, userIp } = useUser();

    const handleImageFormatError = () => {
        setModal({
            size: 'large',
            content:
                <div className='w-[256px] mx-auto flex flex-col justify-center items-center text-center'>
                    <img src={errorImage} />
                    <div className='py-[24px]'>
                        {messages.error.unsuported_image_format}
                    </div>
                    <Button primary={true} label="Try again" icon={regenerate} className="rounded" onClick={() => setIsModalOpen(false)} />
                </div>
        })
        setIsModalOpen(true);
    }

    const isValidFileType = (file) => {
        const validTypes = ['image/jpeg', 'image/png', 'image/heic'];
        return validTypes.includes(file.type);
    };

    useEffect(() => {
        if (result && previews.length > 0) {
            const img = new Image();
            img.onload = () => {
                setSize({ ...size, width: img.width < 900 ? img.width : 900 });
            };
            img.src = previews[0];
        }
    }, [result, previews])

    const handleGenerate = useCallback(async () => {
        setIsLoading(true);
        setOriginalIndex(null);
        setGeneratedIndex(null);
        setAiResult(false);
        setAiMessage('');
        const formData = new FormData();
        formData.append('message', 'Make it look exactly like the image');
        formData.append('code', '<div></div>');
        formData.append('version', packageJson.version);
        formData.append('user', JSON.stringify(user));
        formData.append('userIp', userIp);
        selectedFiles.forEach((file) => {
            formData.append('images', file);
        });

        try {
            const response = await fetch(`${REACT_APP_SERVER}/request/initiate`, {
                method: 'POST',
                body: formData,
            });

            const originalIndex = await forceTakeSnapshot('Original');
            setOriginalIndex(originalIndex);

            let data;
            try {
                data = await response.json();
            } catch (error) {
                console.error('Error parsing response JSON:', error);
                setSelectedFiles([]);
                setPreviews([]);
                setSnapDescription(null);
                setIsLoading(false);
                clearInterval(intervalId);
                return;
            }

            if (response.ok) {
                const { hash } = data;
                setLatestHash(hash);

                const intervalId = setInterval(async () => {
                    const statusResponse = await fetch(`${REACT_APP_SERVER}/request/status/${hash}`);
                    let statusData;
                    try {
                        statusData = await statusResponse.json();
                    } catch (error) {
                        console.error('Error parsing status JSON:', error);
                        setSelectedFiles([]);
                        setPreviews([]);
                        setSnapDescription(null);
                        setIsLoading(false);
                        clearInterval(intervalId);
                    }

                    if (statusData.status === 'completed') {
                        setHash(hash);
                        setSnapDescription(null);
                        setCode(statusData.code);
                        setCodeAuthor('AI');
                        setIsLoading(false);
                        setResult(true);
                        clearInterval(intervalId);
                    }
                }, 1000);
                setIntervalId(intervalId);
            } else {
                console.error('Error initiating request:', data.message);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error initiating request:', error);
            setIsLoading(false);
        }
    }, [selectedFiles]);

    useEffect(() => {
        if (selectedFiles.length > 0) {
            handleGenerate();
        }
    }, [selectedFiles, handleGenerate]);

    const handleStopGeneration = useCallback(() => {
        if (intervalId) {
            clearInterval(intervalId);
            setIsLoading(false);
        }
    }, [intervalId]);

    useEffect(() => {
        if (!latestHash || !code) return;

        let attempts = 0;
        const maxAttempts = 10;

        const codeRenderIntervalId = setInterval(() => {
            if (codeStatus === 'rendered' && latestHash === hash) {
                forceTakeSnapshot('Generated').then(generatedIndex => {
                    setHash(null);
                    setGeneratedIndex(generatedIndex);
                    clearInterval(codeRenderIntervalId);
                });
            }

            attempts += 1;
            if (attempts >= maxAttempts) {
                clearInterval(codeRenderIntervalId);
            }
        }, 1000);

        return () => clearInterval(codeRenderIntervalId);
    }, [codeStatus, latestHash, code, hash]);

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const files = Array.from(e.dataTransfer.files).filter(isValidFileType);
        if (files.length > 0) {
            handleStopGeneration();
            setSelectedFiles(files);
            const newPreviews = files.map((file) => URL.createObjectURL(file));
            setPreviews(newPreviews);
        } else {
            handleImageFormatError();
        }
        setIsDragging(false);
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files).filter(isValidFileType);
        if (files.length > 0) {
            handleStopGeneration();
            setSelectedFiles(files);
            const newPreviews = files.map((file) => URL.createObjectURL(file));
            setPreviews(newPreviews);
        } else {
            handleImageFormatError();
        }
    };

    const handleRemoveAllFiles = () => {
        handleStopGeneration();
        setResult(false);
        setSelectedFiles([]);
        setPreviews([]);
        document.querySelector('#ai-images').value = "";
    };

    return (
        <ImageContext.Provider value={{
            isOpen, setIsOpen,
            isLoading, setIsLoading,
            isDragging, setIsDragging,
            result, setResult,
            originalIndex, setOriginalIndex,
            generatedIndex, setGeneratedIndex,

            previews,

            handleGenerate,
            handleStopGeneration,

            handleDrop,
            handleFileChange,
            handleRemoveAllFiles,
        }}>
            {children}
        </ImageContext.Provider>
    );
};
