import React, { useState, useEffect } from 'react';
import spinner from "../img/spinner.svg";

export const Spinner = ({ size, ...rest }) => {
    const [rotation, setRotation] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setRotation((prevRotation) => prevRotation + 45);
        }, 120);

        return () => clearInterval(interval);
    }, []);

    return (
        <img src={spinner} style={{ transform: `rotate(${rotation}deg)`, width: size, height: size }} {...rest} />
    );
};
