export const messages = {
    login: {
        // title: "Join Webcrumbs AI!",
        // paragraph: "Sign up to unleash more AI-powered coding magic",
        // unknown_error: "Oops! Something went wrong. Please give it another try in a bit.",
        title: null,
        paragraph: "Welcome to Frontend AI. Please log in to continue.",
        unknown_error: "Something went wrong. Please try again later.",
        password_error: "Check your password and try again.",
        permissions_error: "Make sure you have the permissions to access this app."
    },
    empty_state: {
        returning_user: "Hello! Would you like to continue from where you left off last time, or start a new coding project?",
        first_access: <>No code available at the moment.<br />Upload an image or ask AI to begin your coding process.</>
    },
    error: {
        unsuported_image_format:  "Unsupported file format. Please upload in JPG, PNG, or HEIC format.",
    },
    navbar: {
        get_code: "Component code"
    }
}