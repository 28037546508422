import warning from "../../img/warning.svg";
import { Button } from "../Button";
import regenerate from "../../img/regenerate.svg";
import { useAi } from "../../hooks";


export const Error = () => {
    const { setResult, setMessage, message, handleGenerate, setError } = useAi();

    const handleRetry = () => {
        setMessage(message);
        setResult(false);
        setError(false);
        handleGenerate();
    }

    return (
        <div className="w-[344px] h-[136px] p-3 rounded-xl border border-red-200 flex-col justify-center items-center gap-[12px] inline-flex">
            <div className="justify-start items-center gap-2 inline-flex">
                <img src={warning} className="w-[17px] h-[15px]" alt="Warning" />
                <div className="text-center text-[#EC2617] text-sm font-medium leading-tight">Oops! Your request failed. Try again!</div>
            </div>
            <div className="bg-white rounded-l justify-center items-center gap-3 inline-flex">
                <div className="justify-center items-center gap-1.5 flex">
                    <div className="pr-0.5 justify-center items-center gap-2.5 flex">
                    <Button className="h-[32px] rounded-[8px]"
                        icon={regenerate} 
                        alt="Regenerate"
                        label="Retry" 
                        onClick={handleRetry}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
