// src/components/Code/StyleCode.js
import React, { useEffect, useState } from "react";
import Prism from 'prismjs';
import 'prismjs/components/prism-cshtml';
import './code.css';
import * as prettier from 'prettier/standalone';
import * as parserPostCSS from 'prettier/parser-postcss';
import copy from '../../img/copy.svg';
import check from '../../img/check.svg';
import { Tooltip } from "../Tooltip";
import { useTheme } from "../../hooks";

export const StyleCode = ({ size }) => {
    const [copied, setCopied] = useState(false);
    const [formattedCode, setFormattedCode] = useState('');
    const { css, preCss, classes } = useTheme();

    useEffect(() => {
        const formatCode = async () => {
            if (classes.length > 0) {
                try {
                    const code = await prettier.format(preCss.join('\n') + '\n' + css, {
                        parser: 'css',
                        plugins: [parserPostCSS],
                    });
                    setFormattedCode(code);
                } catch (error) {
                    console.error("Error formatting code:", error);
                }
            } else {
                setFormattedCode('');
            }
        };

        formatCode();
    }, [css, preCss, classes]);

    useEffect(() => {
        Prism.highlightAll()
    }, [formattedCode])

    const handleClick = () => {
        navigator.clipboard.writeText(css)
            .then(() => {
                setCopied(true);
                setTimeout(() => {
                    setCopied(false);
                }, 600);
            })
    };

    return (
        <div className={`${size} relative bg-code-01 border border-l border-gray-03 border-t border-gray-03 flex flex-col overflow-hidden`}>
            <div className={`px-[24px] pt-[16px] pb-[8px] text-white focus:outline-none text-base font-semibold leading-normal flex items-center`}>
                Your CSS
                <Tooltip position="center" badge="Not editable">
                    Use the right menu to change the <br />
                    style and we will update your CSS.
                </Tooltip>
            </div>
            <pre
                className="bg-transparent w-full h-full flex-grow p-[24px] pt-[12px] font-mono focus:outline-none whitespace-pre-wrap overflow-auto overscroll-none opacity-70"
            >
                <code className="language-css text-red">
                    {formattedCode || 'CSS'}
                </code>
            </pre>

            <div
                className="absolute top-0 right-0 p-[16px] opacity-50 hover:opacity-100 transition-opacity cursor-pointer"
                onClick={handleClick}
            >
                <div className="w-[32px] h-[32px] rounded bg-code-02 flex items-center justify-center">
                    <img src={copied ? check : copy} className="h-[20px] w-[20px]" />
                </div>
            </div>
        </div>
    )
}
