import React, { createContext,  useState } from "react";

export const SnippetContext = createContext();

export const SnippetProvider = ({ children }) => {
    const [background, setBackground] = useState('#E7EBED');
    
    
    return (
        <SnippetContext.Provider value={{ background, setBackground }}>
            {children}
        </SnippetContext.Provider>
    );
};
