import { useEffect, useRef } from "react";
import { useImage, useSnapshot } from "../../hooks";
import upload from "../../img/upload.svg";
import stop from '../../img/stop.svg';
import close from '../../img/icons/close.svg';
import generate from "../../img/generate-white.svg";
import regenerate from '../../img/regenerate.svg';

import { Button } from '../Button';
import { AskAIButton } from "../AskAI/AskAIButton";

export const ImagePanel = () => {
    const {
        isLoading,
        previews,
        handleDrop,
        handleFileChange,
        handleRemoveAllFiles,
        handleStopGeneration,
        isDragging,
        setIsDragging,
        setIsOpen,
        result,
        handleGenerate,
        originalIndex,
        setResult
    } = useImage();
    const { navigateHistory } = useSnapshot();
    const fileInputRef = useRef(null);

    useEffect(() => {
        const handlePaste = (e) => {
            if (e.clipboardData && e.clipboardData.items) {
                const items = e.clipboardData.items;
                for (let i = 0; i < items.length; i++) {
                    if (items[i].type.indexOf("image") !== -1) {
                        const blob = items[i].getAsFile();
                        const file = new File([blob], "pasted-image.png", { type: blob.type });
                        handleFileChange({ target: { files: [file] } });
                        break;
                    }
                }
            }
        };

        document.addEventListener("paste", handlePaste);
        return () => {
            document.removeEventListener("paste", handlePaste);
        };
    }, [handleFileChange]);

    const handleClosePanel = () => {
        setIsOpen(false);
        handleRemoveAllFiles();
    }

    const handleAttach = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleDiscardChanges = () => {
        navigateHistory(originalIndex);
        handleRemoveAllFiles();
        setResult(false);
    }

    return (
        <div
            onDrop={handleDrop}
            onDragOver={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsDragging(true);
            }}
            onDragLeave={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsDragging(false);
            }}
        >
            <h2 className="font-semibold text-base text-gray-02">Image</h2>
            <p className="text-gray-05 font-[500] leading-[20px] mt-[8px]">Upload an image of the component for which you want our AI to create the magic code.</p>
            <div className={`
                mb-[16px] mt-[20px] rounded
                flex flex-col items-center justify-center
                ${previews.length > 0 ? 'h-[166px]' : 'h-[120px]'}
                ${(isDragging || previews.length > 0) ? 'bg-gray-11' : 'border border-dashed border-gray-08'}
                `}>
                {previews.length > 0 ?
                    <div className="h-full w-full bg-gray-11 rounded">
                        {previews.map((preview, index) => (
                            <div key={index} className="group h-full rounded mb-[12px] relative flex items-center justify-center">
                                <img
                                    src={preview}
                                    className="max-w-full max-h-full p-[6px] rounded"
                                    alt={`preview-${index}`}
                                />
                                <div
                                    className="absolute bg-gray-10 -top-1 -right-1 w-4 h-4 cursor-pointer rounded flex justify-center items-center opacity-0 group-hover:opacity-100"
                                    alt="Remove"
                                    onClick={handleRemoveAllFiles}
                                >
                                    <img src={close} width={14} />
                                </div>
                            </div>
                        ))}
                    </div>
                    :
                    <div
                        className="h-full w-full flex flex-col items-center justify-center cursor-pointer"
                        onClick={handleAttach}
                    >
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M31.6667 5H8.33333C6.49238 5 5 6.49238 5 8.33333V31.6667C5 33.5076 6.49238 35 8.33333 35H31.6667C33.5076 35 35 33.5076 35 31.6667V8.33333C35 6.49238 33.5076 5 31.6667 5Z" stroke="#9AA2A7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M14.1665 16.6665C15.5472 16.6665 16.6665 15.5472 16.6665 14.1665C16.6665 12.7858 15.5472 11.6665 14.1665 11.6665C12.7858 11.6665 11.6665 12.7858 11.6665 14.1665C11.6665 15.5472 12.7858 16.6665 14.1665 16.6665Z" stroke="#9AA2A7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M35.0002 24.9998L26.6668 16.6665L8.3335 34.9998" stroke="#9AA2A7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <p className="text-gray-06 mt-[8px]">
                            {isDragging ? 'Drop your image here...' : 'Drag and drop image of component'}
                        </p>
                    </div>
                }
            </div>
            {isLoading ?
                <div className="flex flex-col gap-[12px]">
                    <Button
                        icon={upload} primary={true}
                        loading={isLoading}
                        disabled={isLoading}
                        label={isLoading ? "Generating..." : "Upload image"}
                        className="rounded w-full"
                        onClick={handleAttach}
                    />
                    <Button
                        icon={stop}
                        label="Stop generation"
                        className="rounded w-full"
                        onClick={handleStopGeneration}
                    />
                </div>
                :
                result ?
                    <div className="flex flex-col gap-[12px]">
                        <AskAIButton>
                            <Button
                                icon={generate} primary={true}
                                label={"Ask modifications"}
                                className="rounded w-full"
                                onClick={handleClosePanel}
                            />
                        </AskAIButton>
                        <Button
                            icon={regenerate}
                            label="Regenerate"
                            className="rounded w-full"
                            onClick={handleGenerate}
                        />
                    </div>
                    :
                    <Button
                        icon={upload} primary={true}
                        loading={isLoading}
                        disabled={isLoading}
                        label={isLoading ? "Generating..." : "Upload image"}
                        className="rounded w-full"
                        onClick={handleAttach}
                    />
            }
            <input
                id="ai-images"
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
                multiple
            />
        </div>
    )
}
