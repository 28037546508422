import React, { useRef, useEffect, useCallback, useState } from "react";
import ReactDOM from "react-dom";
import { useCode, useTheme, useSnapshot, useApp, useImage } from "../hooks";
import { EmptyState } from "./EmptyState";

export const Main = () => {
    const { Component, codeStatus, code, size, setSize } = useCode();
    const { result: imageResult } = useImage();
    const { setShadowRoot, themeStatus, css } = useTheme();
    const { takeSnapshot, snapDescription } = useSnapshot();
    const shadowRootRef = useRef(null);
    const { emptyState } = useApp();
    
    const [scale, setScale] = useState(1);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const [startDrag, setStartDrag] = useState({ x: 0, y: 0 });

    const handleWheel = useCallback((event) => {
        event.preventDefault();
        const scaleAmount = -0.001;
        const newScale = Math.exp(Math.log(scale) + event.deltaY * scaleAmount);
        setScale(Math.min(Math.max(0.5, newScale), 3));
    }, [scale]);    

    const handleMouseDown = (event) => {
        setIsDragging(true);
        setStartDrag({ x: event.clientX - position.x, y: event.clientY - position.y });
    };

    const handleTouchStart = (event) => {
        const touch = event.touches[0];
        setIsDragging(true);
        setStartDrag({ x: touch.clientX - position.x, y: touch.clientY - position.y });
    };

    const handleMouseMove = (event) => {
        if (isDragging) {
            setPosition({
                x: event.clientX - startDrag.x,
                y: event.clientY - startDrag.y,
            });
        }
    };

    const handleTouchMove = (event) => {
        if (isDragging) {
            const touch = event.touches[0];
            setPosition({
                x: touch.clientX - startDrag.x,
                y: touch.clientY - startDrag.y,
            });
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleTouchEnd = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        if (shadowRootRef.current && !shadowRootRef.current.shadowRoot) {
            const shadow = shadowRootRef.current.attachShadow({ mode: 'open' });
            setShadowRoot(shadow);
            shadow.innerHTML = `
                <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css" />
                <div id="webcrumbs" class="container">
                    <div class="content" id="content"></div>
                </div>
            `;

            shadow.addEventListener('click', (e) => {
                const target = e.target;

                // prevents onClick window.location
                if (target.onclick) {
                    const onClickHandler = target.onclick.toString();
                    if (onClickHandler.includes('window.location.href')) {
                        e.stopPropagation();
                        e.preventDefault();
                    }
                    // prevents links
                } else if (target.tagName === 'A') {
                    e.stopPropagation();
                    e.preventDefault();
                    // prevents form submissions
                } else if (target.tagName === 'FORM') {
                    e.stopPropagation();
                    e.preventDefault();
                    // prevents input type submit
                } else if (target.tagName === 'INPUT' && target.type === 'submit') {
                    e.stopPropagation();
                    e.preventDefault();
                }

            }, true);

            // prevents form submissions triggered by enter key press
            shadow.addEventListener('submit', (e) => {
                e.stopPropagation();
                e.preventDefault();
            }, true);

        }
    }, [setShadowRoot]);

    useEffect(() => {
        const updateHeight = () => {
            if (imageResult && themeStatus === 'rendered' && codeStatus === 'rendered' && shadowRootRef && shadowRootRef.current) {
                const height = shadowRootRef.current.clientHeight;
                setSize((prevSize) => ({ ...prevSize, height }));
            }
        };
        updateHeight();
    }, [imageResult, shadowRootRef, themeStatus, codeStatus]);

    useEffect(() => {
        if (snapDescription && themeStatus === 'rendered' && codeStatus === 'rendered') {
            takeSnapshot();
        }
    }, [themeStatus, codeStatus, snapDescription]);

    useEffect(() => {
        const handleMouseMoveGlobal = (event) => handleMouseMove(event);
        const handleTouchMoveGlobal = (event) => handleTouchMove(event);
        const handleMouseUpGlobal = () => setIsDragging(false);
        const handleTouchEndGlobal = () => setIsDragging(false);

        document.addEventListener('mousemove', handleMouseMoveGlobal);
        document.addEventListener('mouseup', handleMouseUpGlobal);
        document.addEventListener('touchmove', handleTouchMoveGlobal);
        document.addEventListener('touchend', handleTouchEndGlobal);

        return () => {
            document.removeEventListener('mousemove', handleMouseMoveGlobal);
            document.removeEventListener('mouseup', handleMouseUpGlobal);
            document.removeEventListener('touchmove', handleTouchMoveGlobal);
            document.removeEventListener('touchend', handleTouchEndGlobal);
        };
    }, [isDragging]);

    return (
        <div className="relative flex-1 h-full flex flex-col" onWheel={handleWheel} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
            <div className="absolute overflow-hidden inset-0 w-full h-[calc(100%-56px)] p-[24px] pt-[80px]">
                <div className="mx-auto h-[calc(100%-120px)]" style={{ width: `${size.width}px` }}>
                    <div className={`h-full mt-[56px] ${emptyState ? "opacity-100" : "hidden"}`}>
                        <EmptyState />
                    </div>
                    <div 
                        ref={shadowRootRef} 
                        className={`rounded ${emptyState ? "hidden" : "opacity-100"} ${(themeStatus === "loading" || codeStatus === "loading") ? "opacity-80" : "visible"}`}
                        style={{ transform: `translate(${position.x}px, ${position.y}px) scale(${scale})` }}
                        onMouseDown={handleMouseDown}
                        onTouchStart={handleTouchStart}
                    >
                        {shadowRootRef.current && shadowRootRef.current.shadowRoot && (
                            <>
                                {Component && ReactDOM.createPortal(<Component />, shadowRootRef.current.shadowRoot.querySelector('#content'))}
                            </>
                        )}
                    </div>
                </div>
            </div>
            {/* <TempControls /> */}
        </div>
    );
};
