import { useEffect, useState, useMemo } from "react";
import tippy from '../img/tippy.svg'
import info from "../img/info-icon.svg";

const Badge = ({ badge, children }) => {
    return (
        <div className="px-[8px] h-[22px] bg-gray-05 rounded flex flex-row justify-center items-center ml-2">
            <div className="text-gray-09 text-xs font-normal mr-[4px]">{badge}</div>
            {children}
        </div>
    )
}

export const Tooltip = ({ children, badge, icon = <img src={info} />, position, margin, message, ...rest }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isCooldown, setIsCooldown] = useState(false);

    useEffect(() => {
        if (!isVisible) return;

        const hideTooltip = () => {
            setIsVisible(false);
            setIsCooldown(true);
            setTimeout(() => setIsCooldown(false), 2000);
        };

        const timer = setTimeout(hideTooltip, 2000);

        return () => clearTimeout(timer);
    }, [isVisible]);

    const Info = useMemo(() => (
        <div className="relative" {...rest}>
            {message && children}
            {!message && icon}
            {isVisible && (
                <div
                    className={`
                    inline-block absolute z-100 max-w-[232px] text-nowrap
                    ${position === 'center' ? 'left-1/2 transform -translate-x-1/2' : position === 'right' ? 'left-0 ml-[12px]' : 'right-0 -mr-[8px]'}
                    ${margin ? margin : badge ? 'mt-[8px]' : 'mt-[7px]'}
                    px-[12px] py-[8px]
                    text-xs font-[400] text-gray-10 
                    bg-gray-03 border border-gray-04 rounded-[6px]
                `}
                >
                    {message || children}
                    <img
                        src={tippy}
                        className={`
                            absolute -top-[6px] 
                            ${position === 'center' ? 'left-1/2 transform -translate-x-1/2' : position === 'right' ? 'left-[8px]' : 'right-[8px]'}
                        `}
                    />
                </div>
            )}
        </div>
    ), [isVisible, message, children, icon, position, margin, badge]);

    const handleMouseEnter = () => {
        if (!isCooldown) {
            setIsVisible(true);
        }
    };

    return (
        <div onMouseEnter={handleMouseEnter} onMouseLeave={() => setIsVisible(false)} {...rest}>
            {badge ? <Badge badge={badge}>{Info}</Badge> : Info}
        </div>
    );
};
