import { useApp } from "../../hooks";
import { RightPanelContent } from "./RightPanelContent";
import { useState, useEffect } from "react";

export const RightPanel = () => {
    const { rightPanelOpen } = useApp();
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (rightPanelOpen) {
            setIsVisible(true);
        } else {
            const timer = setTimeout(() => setIsVisible(false), 300);
            return () => clearTimeout(timer);
        }
    }, [rightPanelOpen]);

    return (
        <div
            className={`flex flex-row relative transition-all duration-300 ease-in-out border-l border-gray-10  ${isVisible ? 'visible' : 'invisible'}`}
            style={{
                width: rightPanelOpen ? '300px' : '0px',
                opacity: rightPanelOpen ? 1 : 0,
            }}
        >
            <div className="absolute bg-white flex flex-col relative transition-transform duration-300 ease-in-out transform menu">
                <RightPanelContent />
            </div>
        </div>
    );
};
