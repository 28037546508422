import { useEffect, useState } from 'react';
import { uniqueNamesGenerator, adjectives, colors, animals } from 'unique-names-generator';

const generateReadableUserId = () => {
  return uniqueNamesGenerator({ dictionaries: [adjectives, colors, animals], separator: '-', length: 3 });
};

export const useUserId = () => {
  const [ga4ClientId, setGa4ClientId] = useState();
  const [clarityUserId, setClarityUserId] = useState();

  let guestId = localStorage.getItem('guestId');

  if (!guestId) {
    guestId = generateReadableUserId();
    localStorage.setItem('guestId', guestId);
  }

  useEffect(() => {
    const fetchGA4ClientId = () => {
      if (window.gtag) {
        window.gtag('get', 'G-K3166Z26NJ', 'client_id', (client_id) => {
          setGa4ClientId(client_id);
        });
      }
    };

    const fetchClarityUserId = () => {
      if (typeof window.clarity === 'function') {
        try {
          const userId = guestId;
          window.clarity("set", "guestId", guestId);
          setClarityUserId(guestId);
        } catch (error) {
        }
      } else {
      }
    };

    const checkLibrariesLoaded = () => {
      if (!ga4ClientId) fetchGA4ClientId();
      if (!clarityUserId) fetchClarityUserId();

      if (ga4ClientId && clarityUserId) {
        clearInterval(intervalId);
      }
    };

    const intervalId = setInterval(checkLibrariesLoaded, 1000);

    return () => clearInterval(intervalId);
  }, [ga4ClientId, clarityUserId, guestId]);

  return { guestId, ga4ClientId, clarityUserId };
};
