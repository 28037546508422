import React from "react";
import { NavBar } from "../components/NavBar";
import { Main } from "../components/Main";
import { RightPanel } from "../components/RightPanel";
import { LeftPanel } from "../components/LeftPanel";
import { Code } from "../components/Code";
import { ThemeProvider } from "../contexts/ThemeContext";
import { CodeProvider } from "../contexts/CodeContext";
import { SnapshotProvider } from "../contexts/SnapshotContext";
import { AiProvider } from "../contexts/AiContext";
import MobileWarning from "../components/MobileWarning";
// import BadgeBtn from "../components/BadgeBtn";
import { AIModal } from "../components/AskAI/AIModal";
import { ImageProvider } from "../contexts/ImageContext";
import { AppProvider } from "../contexts/AppContext";
import { Modals } from "../components/Modals";
import { Nudge } from "../components/Nudge";
import { UserProvider } from "../contexts/UserContext";
import { SnippetProvider } from "../contexts/SnippetContext";
import { CustomizationProvider } from "../contexts/CustomizationContext";


export const Tool = () => {
    return (
        <CustomizationProvider>
            <UserProvider>
                <ThemeProvider>
                    <div className="block sm:hidden h-full">
                        <MobileWarning />
                    </div>
                    <CodeProvider>
                        <SnapshotProvider>
                            <AppProvider>
                                <AiProvider>
                                    <ImageProvider>
                                      <SnippetProvider>
                                        <div className="bg-neutral-01 flex h-screen overflow-hidden flex-col justify-center hidden sm:flex">
                                            <NavBar />
                                            <div className="flex flex-1 w-full">
                                                <LeftPanel />
                                                <Main />
                                                {/* <div className="fixed right-[300px] bottom-[56px] justify-center">
                                                    <BadgeBtn />
                                                </div> */}
                                                <RightPanel />
                                                <Code />
                                            </div>
                                        </div>
                                        <Modals />
                                        <AIModal />
                                    </SnippetProvider>
                                    </ImageProvider>
                                </AiProvider>
                            </AppProvider>
                        </SnapshotProvider>
                    </CodeProvider>
                </ThemeProvider>
            </UserProvider>
        </CustomizationProvider>
    );
};
