import React from "react";
import { ThemeSelector } from "../ThemeSelector";
import { useCode, useSnapshot, useTheme } from "../../hooks";
import { ColorPicker } from "../ColorPicker";
import { FontSelector } from "../FontSelector";

export const RightPanelContent = () => {
    const { theme, updateTheme, setThemeStatus } = useTheme();
    const { size, setSize, setCodeStatus } = useCode();
    const { setSnapDescription } = useSnapshot();

    return (
        <div className="fixed h-full">
            <div className="flex-grow bg-white h-full w-[300px] overflow-visible divide-y divide-neutral-2">
                <div className="p-[20px] pb-[10px]">
                    <ThemeSelector />
                    <ColorPicker
                        id="primary"
                        label="Main color"
                        defaultColor="#FA5B30"
                        replace="blue"
                    />
                    <ColorPicker
                        id="neutral"
                        label="Background color"
                        defaultColor="#FAFAFA"
                        replace="white"
                    />
                </div>
                <div className="p-[20px] pb-[10px]">
                    <FontSelector />
                    <div className="flex flex-wrap -mx-2 mb-4 space-y-4 md:space-y-0">
                        <div className="px-2 w-full">
                            <label htmlFor="fontSize" className="block text-sm font-medium text-gray-01">Font size</label>
                            <input
                                type="number"
                                id="fontSize"
                                value={theme.fontSize?.baseValue || 14}
                                onChange={(e) => {
                                    const newValue = !isNaN(parseInt(e.target.value, 10)) && parseInt(e.target.value, 10) > 0 ? parseInt(e.target.value, 10) : 16;
                                    if (newValue !== theme.fontSize?.baseValue) {
                                        updateTheme('fontSize.baseValue', newValue);
                                        setSnapDescription('Changed font size');
                                        setCodeStatus('rendered');
                                    }
                                }}
                                className="mt-1 block w-full pl-3 pr-3 py-2 text-base border-gray-10 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded border-solid border border-gray-10"
                            />
                        </div>
                    </div>
                </div>
                <div className="p-[20px] pb-[10px]">
                    <div className="flex flex-wrap -mx-2 mb-4 space-y-4 md:space-y-0">
                        <div className="px-2 w-full md:w-1/2">
                            <label htmlFor="radius" className="block text-sm font-medium text-gray-01">Radius</label>
                            <input
                                type="number"
                                id="radius"
                                value={theme.borderRadius?.baseValue * 2|| 0}
                                onChange={(e) => {
                                    const newValue = !isNaN(parseInt(e.target.value, 10)) && parseInt(e.target.value, 10) > 0 ? parseInt(e.target.value, 10) / 2 : 0
                                    if (newValue !== theme.borderRadius?.baseValue) {
                                        updateTheme('borderRadius.baseValue', newValue);
                                        setSnapDescription('Changed border radius');
                                        setCodeStatus('rendered');
                                    }
                                }}
                                className="mt-1 block w-full pl-3 pr-3 py-2 text-base border-gray-10 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded border-solid border border-gray-10"
                                placeholder="Radius"
                            />
                        </div>
                        <div className="px-2 w-full md:w-1/2">
                            <label htmlFor="spacing" className="block text-sm font-medium text-gray-01">Spacing</label>
                            <input
                                type="number"
                                id="spacing"
                                value={theme.spacing?.baseValue || 0}
                                onChange={(e) => {
                                    const newValue = !isNaN(parseInt(e.target.value, 10)) && parseInt(e.target.value, 10) > 0 ? parseInt(e.target.value, 10) : 0
                                    if (newValue !== theme.spacing?.baseValue) {
                                        updateTheme('spacing.baseValue', newValue);
                                        setSnapDescription('Changed spacing');
                                        setCodeStatus('rendered');
                                    }
                                }}
                                className="mt-1 block w-full pl-3 pr-3 py-2 text-base border-gray-10 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded border-solid border border-gray-10"
                                placeholder="Spacing"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
