import React, { useEffect, useState } from "react";
import { AskAIButton } from "./AskAI/AskAIButton";
import ai_icon from "../img/icons/ai_white.svg"
import image from "../img/image.svg"
import plus from "../img/plus.svg"
import regenerate from "../img/regenerate.svg";
import { ImageButton } from "./Image/ImageButton";
import { Button } from "./Button";
import { useApp, useCode, useCustomization, useSnapshot, useTheme } from "../hooks";

const State = ({ leftButton, rightButton, text, img }) => {
    return (
        <div className="flex flex-1 flex-col h-full items-center justify-center">
            <div className="flex flex-col items-center justify-center -mt-[64px]">
                {img}
                <div className="mt-[20px] text-center text-gray-03 text-[14px] font-[500] leading-[20px] max-w-[412px]">
                    {text}
                </div>
                <div className="flex flex-row gap-[24px] mt-[24px]">
                    {leftButton}
                    {rightButton}
                </div>
            </div>
        </div>
    )
}

export const EmptyState = () => {
    const { messages, images } = useCustomization();
    const { getUndoRedoInfo, navigateHistory } = useSnapshot();
    const [lastCursor, setLastCursor] = useState();
    const { setCss } = useTheme();
    const { setCode } = useCode();
    const { setEmptyState } = useApp();

    useEffect(() => {
        const fetchUndoRedoInfo = async () => {
            const info = await getUndoRedoInfo();
            setLastCursor(info.lastCursor);
        };

        fetchUndoRedoInfo();
    }, []);

    const handleStartFromScratch = () => {
        setCode("<div></div>");
        setCss("");
        setEmptyState(false);
    }

    if (lastCursor) {
        return (
            <State
                leftButton={
                    <Button
                        label="Resume session"
                        onClick={() => navigateHistory(lastCursor)}
                        icon={regenerate}
                        className="rounded font-semibold px-[16px]"
                    />
                }
                rightButton={
                    <AskAIButton>
                        <Button
                            label="Ask AI"
                            icon={ai_icon}
                            className="rounded font-semibold px-[16px]"
                            primary={true}
                        />
                    </AskAIButton>
                }
                text={messages.empty_state.returning_user}
                img={images.empty_state_returning_user}
            />
        )
    } else {
        return (
            <State
                leftButton={
                    <ImageButton>
                        <Button label="Add image" icon={image} className="rounded font-semibold px-[16px]" />
                    </ImageButton>}
                rightButton={
                    <AskAIButton allowClose={false}>
                        <Button primary={true} label="Ask AI" icon={ai_icon} className="rounded font-semibold px-[16px]" />
                    </AskAIButton>
                }
                text={messages.empty_state.first_access}
                img={images.empty_state_first_access}
            />
        )
    }
};
