// contexts/CodeContext.js
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useTheme } from '../hooks';

const { REACT_APP_SERVER } = process.env;
export const CodeContext = createContext();

export const CodeProvider = ({ children }) => {
    const [code, setCode] = useState('<div></div>');
    const [codeAuthor, setCodeAuthor] = useState();
    const [codeStatus, setCodeStatus] = useState();
    const [Component, setComponent] = useState();
    const [componentStatus, setComponentStatus] = useState();
    const [transformedCode, setTransformedCode] = useState();
    const [size, setSize] = useState({ width: 400, height: 400 });
    const [firstRender, setFirstRender] = useState(true);
    const [latestHash, setLatestHash] = useState(null);
    const { setClasses, setPreCss } = useTheme();

    const generateComponent = (transformedCode) => {
        const renderFunc = new Function('React', `{ 
            return function CustomComponent() { 
                return ${transformedCode};
            } 
        }`);

        const safeReact = {
            createElement: React.createElement,
            Component: React.Component,
            Fragment: React.Fragment,
        };

        const ResultingComponent = renderFunc(safeReact);
        return ResultingComponent;
    }

    // Function to render component sending the current code to the API endpoint transform-jsx
    const renderComponent = useCallback(async () => {
        setComponentStatus('loading');
        try {
            if (!code) {
                setCodeStatus('empty');
                return;
            }
            setCodeStatus('loading');

            const response = await fetch(`${REACT_APP_SERVER}/transform-jsx`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ jsxCode: code })
            });

            if (!response.ok) {
                setCodeStatus('error');
                return;
            }

            const { transformedCode } = await response.json();
            if (!transformedCode) {
                throw Error('Error in transforming the code')
            }
            setTransformedCode(transformedCode);
            const ResultingComponent = generateComponent(transformedCode);
            setComponent(() => ResultingComponent);
            setComponentStatus('rendered');
            setCodeStatus('rendered');
            return true;
        } catch (error) {
            setComponent(null);
            setComponentStatus('error');
            setCodeStatus('error');
            return false;
        }
    }, [code]);

    // Render component when code changes
    useEffect(() => {
        if (firstRender) {
            renderComponent();
            setFirstRender(false);
        } else {
            const timeoutId = setTimeout(() => {
                renderComponent();
            }, 1000);

            return () => clearTimeout(timeoutId);
        }
    }, [renderComponent]);

    // Render component when Ctrl + Enter is clicked
    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
                renderComponent();
            }
        };
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [renderComponent]);

    // Update classes when code changes
    useEffect(() => {
        const classRegex = /className\s*=\s*["']([^"']+)["']/g;
        let match;
        const extractedClasses = new Set();

        while ((match = classRegex.exec(code)) !== null) {
            match[1].split(/\s+/).forEach(cls => extractedClasses.add(cls));
        }

        setClasses(Array.from(extractedClasses));
    }, [code, setClasses]);

    // Set width and height when specified in code
    useEffect(() => {
        const matchWidth = code.match(/<div.*className=".*w-\[(\d+)px\].*">/);
        const matchHeight = code.match(/<div.*className=".*h-\[(\d+)px\].*">/);

        const width = matchWidth ? parseInt(matchWidth[1], 10) : size.width;
        const height = matchHeight ? parseInt(matchHeight[1], 10) : size.height;

        setSize({ width, height });
    }, [code]);

    // Add material icon fonts when specified in code
    useEffect(() => {
        const importUrls = {
            outlined: 'https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200',
            sharp: 'https://fonts.googleapis.com/css2?family=Material+Symbols+Sharp:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200',
            rounded: 'https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200'
        };

        const materialIconsRegex = /className=.*material-symbols-(outlined|sharp|rounded).*/;
        const match = code.match(materialIconsRegex);

        if (match) {
            const iconType = match[1];
            const iconUrl = importUrls[iconType];
            const iconCss = `@import url(${iconUrl});\n`;
            setPreCss(prev => (prev.includes(iconCss) ? prev : [...prev, iconCss]));
        }
    }, [code]);

    // Add Font Awesome icons when specified in code
    useEffect(() => {
        const importUrl = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css';

        const fontAwesomeRegex = /className=.*fa-(brands|solid|regular).*/;
        const match = code.match(fontAwesomeRegex);

        if (match) {
            const iconCss = `@import url(${importUrl});\n`;
            setPreCss(prev => (prev.includes(iconCss) ? prev : [...prev, iconCss]));
        }
    }, [code]);


    return (
        <CodeContext.Provider value={{
            code, setCode,
            codeAuthor, setCodeAuthor,
            codeStatus, setCodeStatus,
            Component, setComponent,
            componentStatus, setComponentStatus,
            size, setSize,
            latestHash, setLatestHash,
            transformedCode, generateComponent
        }}>
            {children}
        </CodeContext.Provider>
    );
};