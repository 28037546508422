// contexts/ThemeContext.js
import React, { createContext, useEffect, useState, useCallback, useRef } from 'react';
import { themes as defaultThemes, fontPairing, customFonts } from '../_customization/themes';
import { generateLayerShades } from '../components/ColorPicker/shadesFactory';
import { useUser } from '../hooks';

const { REACT_APP_SERVER } = process.env;

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const { user, permissions } = useUser();
    const [themes, setThemes] = useState(defaultThemes);
    const [theme, setTheme] = useState(themes[0]);
    const [themeName, setThemeName] = useState(theme?.name);
    const [themeAuthor, setThemeAuthor] = useState();
    const [themeStatus, setThemeStatus] = useState(null);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [saved, setSaved] = useState();

    const [css, setCss] = useState('');
    const [preCss, setPreCss] = useState([]);
    const [shadowRoot, setShadowRoot] = useState(null);
    const [classes, setClasses] = useState([]);
    const [loadedFirstTime, setLoadedFirstTime] = useState(false);

    const debounceTimeout = useRef(null);

    const loadThemes = async () => {
        if (permissions?.canUpdateTheme && user?.id) {
            try {
                const userId = user.id;
                const response = await fetch(`${REACT_APP_SERVER}/custom-themes/${userId}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                if (data?.customThemes) {
                    const themes = Object.values(data.customThemes);
                    setThemes(themes);
                    if (!loadedFirstTime) {
                        setTheme(themes[0]);
                        setThemeName(themes[0]?.name);
                    }
                    setLoadedFirstTime(true);
                }
            } catch (error) {
                console.error('Error fetching themes:', error);
            }
        }
    };

    useEffect(() => {
        loadThemes();
    }, [user, saved]);

    useEffect(() => {
        setThemeName(theme?.name);
    }, [theme])

    // Function to update css
    const loadCSS = useCallback(() => {
        const debounceUpdate = async () => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
                setThemeStatus('loading');
            }

            debounceTimeout.current = setTimeout(async () => {
                try {
                    const cssResponse = await fetch(`${REACT_APP_SERVER}/generate-css`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ theme, classes, important: '#webcrumbs', tailwind: true })
                    });

                    if (!cssResponse.ok) {
                        setThemeStatus('error');
                        console.warn('Failed to generate CSS - server');
                        return;
                    }

                    const { css } = await cssResponse.json();
                    setCss(css);

                    const newStyleElement = document.createElement('style');
                    newStyleElement.textContent = css;

                    const timestamp = Date.now();
                    newStyleElement.setAttribute('data-timestamp', timestamp);

                    newStyleElement.onload = async () => {
                        await shadowRoot.querySelectorAll('style[data-timestamp]').forEach(el => {
                            if (parseInt(el.getAttribute('data-timestamp'), 10) < timestamp) {
                                el.remove();
                            }
                        });
                        setThemeStatus('rendered');
                    };

                    shadowRoot.appendChild(newStyleElement);

                    setThemeAuthor('Human');

                } catch (error) {
                    setThemeStatus('error');
                    console.warn('Failed to generate CSS - client', error);
                }
            }, 300);
        };

        if (shadowRoot && classes.length > 0) {
            debounceUpdate();
        }
    }, [theme, classes, REACT_APP_SERVER, shadowRoot]);

    // Load css whenever there are changes in theme, classes, REACT_APP_SERVER, shadowRoot
    useEffect(() => {
        loadCSS();
    }, [loadCSS]);

    // Function to load fonts if fonts in theme are not yet loaded
    const loadFonts = useCallback(() => {
        const { fontFamily } = theme;

        if (customFonts) {
            Object.keys(customFonts).forEach(fontName => {
                const fontFace = customFonts[fontName];
                if (!document.getElementById(fontName)) {
                    const style = document.createElement('style');
                    style.id = fontName;
                    style.appendChild(document.createTextNode(fontFace));
                    document.head.appendChild(style);
                }
            })
        }

        if (fontFamily && fontFamily.baseValue) {
            setPreCss([]);
            const newPreCss = [];

            fontFamily.baseValue.forEach(fontName => {
                if (googleFonts.includes(fontName)) {
                    const fontUrl = `https://fonts.googleapis.com/css2?family=${fontName.replace(/ /g, '+')}&display=swap`;
                    if (!document.getElementById(fontName)) {
                        const fontLink = document.createElement('link');
                        fontLink.id = fontName;
                        fontLink.rel = 'stylesheet';
                        fontLink.href = fontUrl;
                        document.head.appendChild(fontLink);
                    }
                    const fontCss = `@import url(${fontUrl});\n`;
                    if (!newPreCss.includes(fontCss)) {
                        newPreCss.push(fontCss);
                    }
                } else if (Object.keys(customFonts).includes(fontName)) {
                    const fontCss = `${customFonts[fontName]}\n`;
                    if (!newPreCss.includes(fontCss)) {
                        newPreCss.push(fontCss);
                    }
                }
            });

            setPreCss(newPreCss);
        }
    }, [theme]);

    // Load css whenever there are changes in theme
    useEffect(() => {
        loadFonts();
    }, [loadFonts]);

    function updateUnsavedChages() {
        const matchedTheme = themes.find(t => JSON.stringify(t) === JSON.stringify(theme));
        if (!matchedTheme) {
            setUnsavedChanges(true);
        }
    }

    // Function to update theme specific keys
    function updateTheme(path, value) {
        const keys = path.split('.');
        const lastKey = keys.pop();
        const deepClone = { ...theme };

        let current = deepClone;
        keys.forEach(key => {
            current[key] = { ...current[key] };
            current = current[key];
        });

        current[lastKey] = value;
        setTheme(deepClone);
        updateUnsavedChages();
    }

    // Function to update multiple theme specific keys
    function batchUpdateTheme(updates) {
        const deepClone = { ...theme };
        updates.forEach(([path, value]) => {
            const keys = path.split('.');
            const lastKey = keys.pop();

            let current = deepClone;
            keys.forEach(key => {
                current[key] = { ...current[key] };
                current = current[key];
            });

            if (value === undefined) {
                delete current[lastKey];
            } else {
                current[lastKey] = value;
            }
        });

        setTheme(deepClone);
        updateUnsavedChages();
        setThemeAuthor('Human');
    }

    // Function to update the full theme
    function updateFullTheme(theme) {
        setTheme(theme);
        setThemeAuthor('Human');
    }

    useEffect(() => {
        // if (changedTheme) return;
        const baseValue = theme?.borderRadius?.baseValue || 0;

        updateTheme('borderRadius', {
            baseValue: baseValue,
            none: '0px',
            sm: `${baseValue * 0.5}px`,
            DEFAULT: `${baseValue}px`,
            md: `${baseValue * 1.5}px`,
            lg: `${baseValue * 2}px`,
            xl: `${baseValue * 3}px`,
            '2xl': `${baseValue * 4}px`,
            '3xl': `${baseValue * 6}px`,
            full: '9999px',
        })

    }, [theme?.borderRadius?.baseValue]);

    useEffect(() => {
        // if (changedTheme) return;
        const baseValue = theme?.spacing?.baseValue || 0;

        updateTheme('spacing', {
            baseValue: baseValue,
            px: '1px',
            0: '0px',
            0.5: `${baseValue * 0.5}px`,
            1: `${baseValue * 1}px`,
            1.5: `${baseValue * 1.5}px`,
            2: `${baseValue * 2}px`,
            2.5: `${baseValue * 2.5}px`,
            3: `${baseValue * 3}px`,
            3.5: `${baseValue * 3.5}px`,
            4: `${baseValue * 4}px`,
            5: `${baseValue * 5}px`,
            6: `${baseValue * 6}px`,
            7: `${baseValue * 7}px`,
            8: `${baseValue * 8}px`,
            9: `${baseValue * 9}px`,
            10: `${baseValue * 10}px`,
            11: `${baseValue * 11}px`,
            12: `${baseValue * 12}px`,
            14: `${baseValue * 14}px`,
            16: `${baseValue * 16}px`,
            20: `${baseValue * 20}px`,
            24: `${baseValue * 24}px`,
            28: `${baseValue * 28}px`,
            32: `${baseValue * 32}px`,
            36: `${baseValue * 36}px`,
            40: `${baseValue * 40}px`,
            44: `${baseValue * 44}px`,
            48: `${baseValue * 48}px`,
            52: `${baseValue * 52}px`,
            56: `${baseValue * 56}px`,
            60: `${baseValue * 60}px`,
            64: `${baseValue * 64}px`,
            72: `${baseValue * 72}px`,
            80: `${baseValue * 80}px`,
            96: `${baseValue * 96}px`
        });

    }, [theme?.spacing?.baseValue]);

    useEffect(() => {
        // if (changedTheme) return;
        const baseValue = theme?.fontSize?.baseValue || '16px';

        updateTheme('fontSize', {
            baseValue: baseValue,
            xs: [`${parseFloat(baseValue) * 0.75}px`, { lineHeight: `${parseFloat(baseValue) * 0.75 * (1 / 0.75)}px` }],
            sm: [`${parseFloat(baseValue) * 0.875}px`, { lineHeight: `${parseFloat(baseValue) * 0.875 * (1.25 / 0.875)}px` }],
            base: [`${parseFloat(baseValue)}px`, { lineHeight: `${parseFloat(baseValue) * (1.5 / 1)}px` }],
            lg: [`${parseFloat(baseValue) * 1.125}px`, { lineHeight: `${parseFloat(baseValue) * 1.125 * (1.75 / 1.125)}px` }],
            xl: [`${parseFloat(baseValue) * 1.25}px`, { lineHeight: `${parseFloat(baseValue) * 1.25 * (1.75 / 1.25)}px` }],
            '2xl': [`${parseFloat(baseValue) * 1.5}px`, { lineHeight: `${parseFloat(baseValue) * 1.5 * (2 / 1.5)}px` }],
            '3xl': [`${parseFloat(baseValue) * 1.875}px`, { lineHeight: `${parseFloat(baseValue) * 1.875 * (2.25 / 1.875)}px` }],
            '4xl': [`${parseFloat(baseValue) * 2.25}px`, { lineHeight: `${parseFloat(baseValue) * 2.25 * (2.5 / 2.25)}px` }],
            '5xl': [`${parseFloat(baseValue) * 3}px`, { lineHeight: `${parseFloat(baseValue)}px` }],
            '6xl': [`${parseFloat(baseValue) * 3.75}px`, { lineHeight: `${parseFloat(baseValue)}px` }],
            '7xl': [`${parseFloat(baseValue) * 4.5}px`, { lineHeight: `${parseFloat(baseValue)}px` }],
            '8xl': [`${parseFloat(baseValue) * 6}px`, { lineHeight: `${parseFloat(baseValue)}px` }],
            '9xl': [`${parseFloat(baseValue) * 8}px`, { lineHeight: `${parseFloat(baseValue)}px` }],
        });

    }, [theme?.fontSize?.baseValue]);

    useEffect(() => {
        // if (changedTheme) return;
        const baseValue = theme?.fontFamily?.baseValue || ['Inter', 'Inter'];

        updateTheme('extend.fontFamily', {
            title: [
                baseValue[1],
                'ui-sans-serif',
                'system-ui',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
                '"Noto Color Emoji"',
            ],
            body: [
                baseValue[0],
                'ui-sans-serif',
                'system-ui',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
                '"Noto Color Emoji"',
            ]
        })

    }, [theme?.fontFamily?.baseValue]);

    useEffect(() => {
        // if (changedTheme) return;

        const baseValue = theme?.extend?.colors?.primary?.baseValue || '#FA5B30';
        const shades = generateLayerShades(baseValue, 'primary', 'primary', 'webcrumbs');

        const shadeValues = shades.reduce((acc, shade) => {
            acc[shade.tokenSuffix] = shade.hex;
            return acc;
        }, {});

        updateTheme('extend.colors.primary', { ...shadeValues, baseValue: baseValue });

    }, [theme?.extend?.colors?.primary?.baseValue]);

    useEffect(() => {
        // if (changedTheme) return;

        const baseValue = theme?.extend?.colors?.neutral?.baseValue || '#FFFFFF';
        const shades = generateLayerShades(baseValue, 'neutral', 'neutral', 'webcrumbs');

        const shadeValues = shades.reduce((acc, shade) => {
            acc[shade.tokenSuffix] = shade.hex;
            return acc;
        }, {});

        updateTheme('extend.colors.neutral', { ...shadeValues, baseValue: baseValue });

    }, [theme?.extend?.colors?.neutral?.baseValue]);

    useEffect(() => {
        // if (changedTheme) return;

        const baseValues = theme?.extend?.colors?.baseValue || {};
        Object.keys(baseValues).forEach(colorName => {
            const shades = generateLayerShades(baseValues[colorName], 'neutral', 'neutral', 'webcrumbs');

            const shadeValues = shades.reduce((acc, shade) => {
                acc[shade.tokenSuffix] = shade.hex;
                return acc;
            }, {});

            updateTheme(`extend.colors.${colorName}`, shadeValues);

        })
    }, [theme?.extend?.colors?.baseValue]);

    return (
        <ThemeContext.Provider value={{
            themes, fontPairing,
            themeName, setThemeName,
            saved, setSaved,
            unsavedChanges, setUnsavedChanges,
            theme, setTheme,
            themeAuthor, setThemeAuthor,
            themeStatus, setThemeStatus,
            css, setCss,
            preCss, setPreCss,
            shadowRoot, setShadowRoot,
            classes, setClasses,

            updateTheme, batchUpdateTheme, updateFullTheme,
            loadThemes
        }}>
            {children}
        </ThemeContext.Provider>
    );
};

const googleFonts = [
    "Roboto",
    "Open Sans",
    "Noto Sans JP",
    "Montserrat",
    "Poppins",
    "Lato",
    "Inter",
    "Roboto Condensed",
    "Material Icons",
    "Oswald",
    "Roboto Mono",
    "Noto Sans",
    "Raleway",
    "Rubik",
    "Nunito",
    "Nunito Sans",
    "Playfair Display",
    "Noto Sans KR",
    "Ubuntu",
    "Roboto Slab",
    "Merriweather",
    "Source Sans 3",
    "Noto Sans TC",
    "Plus Jakarta Sans",
    "PT Sans",
    "Kanit",
    "Lora",
    "Work Sans",
    "DM Sans",
    "Mulish",
    "Fira Sans",
    "Titillium Web",
    "Barlow",
    "Manrope",
    "Quicksand",
    "Heebo",
    "IBM Plex Sans",
    "PT Serif",
    "Mukta",
    "Libre Franklin",
    "Karla",
    "Nanum Gothic",
    "Noto Serif",
    "Material Symbols Outlined",
    "Inconsolata",
    "Noto Sans SC",
    "Material Icons Outlined",
    "Noto Color Emoji",
    "Josefin Sans",
    "Hind Siliguri",
    "Bebas Neue",
    "Arimo",
    "Libre Baskerville",
    "Abel",
    "Jost",
    "Dosis",
    "Cabin",
    "Outfit",
    "Noto Serif JP",
    "Dancing Script",
    "PT Sans Narrow",
    "Anton",
    "EB Garamond",
    "Fira Sans Condensed",
    "Noto Sans HK",
    "Bitter",
    "Barlow Condensed",
    "Assistant",
    "Archivo",
    "Teko",
    "Cairo",
    "Source Code Pro",
    "Oxygen",
    "Exo 2",
    "Hind",
    "Chakra Petch",
    "Space Grotesk",
    "Crimson Text",
    "M PLUS Rounded 1c",
    "Signika Negative",
    "Material Icons Round",
    "Pacifico",
    "Prompt",
    "Red Hat Display",
    "Fjalla One",
    "Public Sans",
    "Comfortaa",
    "Lobster",
    "Figtree",
    "Zilla Slab",
    "Asap",
    "Slabo 27px",
    "Overpass",
    "Material Icons Sharp",
    "Cormorant Garamond",
    "Play",
    "Caveat",
    "Rajdhani",
    "DM Serif Display",
    "Lilita One",
    "Maven Pro",
    "IBM Plex Mono",
];