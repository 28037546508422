import React, { createContext } from "react";
import { workspace } from "../_customization/workspace";
import { messages } from "../_customization/messages";
import { images } from "../_customization/images";
import { ctas } from "../_customization/ctas";
import { permissions } from "../_customization/permissions";
import { template } from "../_customization/template";
import { suggestions } from "../_customization/suggestions";

export const CustomizationContext = createContext();

export const CustomizationProvider = ({ children }) => {
    return (
        <CustomizationContext.Provider value={{ messages, images, ctas, permissions, workspace, template, suggestions }}>
            {children}
        </CustomizationContext.Provider>
    );
};