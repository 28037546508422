import React, { useRef, useState, useEffect } from "react";
import { SketchPicker } from 'react-color';

import { Tooltip } from "../Tooltip";
import info from "../../img/info-icon.svg";
import { useTheme } from "../../hooks";
import Checkbox from "../Checkbox";

export const ColorPicker = ({ id, label, defaultColor, replace }) => {
    const { theme, updateTheme, batchUpdateTheme } = useTheme();
    const [isOpen, setOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef, setOpen]);

    const handleReplace = (event) => {
        const checked = event.target.checked;
        if (checked) {
            batchUpdateTheme([
                [`extend.colors.${id}.replace`, checked],
                [`extend.colors.baseValue.${replace}`, theme.extend?.colors?.[id]?.baseValue || defaultColor],
            ])
        } else {
            batchUpdateTheme([
                [`extend.colors.${id}.replace`, checked],
                [`extend.colors.${replace}`, undefined],
            ])
        }
    }

    return (
        <div className="mb-4 relative" ref={dropdownRef}>
            <div className="flex flex-row justify-between">
                <label htmlFor={id} className="block text-sm font-medium">
                    {label}
                </label>
                {replace &&
                    <label className="flex items-center text-gray-04 text-xs font-medium leading-[18px]">
                        {/* <input
                            type="checkbox"z
                            className="mr-2"
                            checked={theme.extend?.colors?.[id]?.replace || false}
                            onChange={handleReplace}
                        /> */}
                        <Checkbox
                            type="checkbox"
                            className="mr-2"
                            checked={theme.extend?.colors?.[id]?.replace || false}
                            onChange={handleReplace}
                        />
                        <span>Replace {replace}</span>
                        <div className="ml-[4px]">
                            <Tooltip>
                                If your code uses {replace} as a color,<br />
                                checking this box will convert it to<br />
                                the selected color.
                            </Tooltip>
                        </div>
                    </label>
                }
            </div>
            <div
                className="mt-1 flex items-center rounded border border-gray-10 h-[40px] px-3 cursor-pointer"
                onClick={() => setOpen(!isOpen)}
            >
                <div className="w-6 h-6 rounded-[4px] border" style={{ backgroundColor: theme.extend?.colors?.[id]?.baseValue || defaultColor }}></div>
                <input
                    type="text"
                    id={id}
                    value={theme.extend?.colors?.[id]?.baseValue || defaultColor}
                    readOnly
                    className="ml-2 block flex-grow py-2 border-gray-10 focus:outline-none sm:text-sm cursor-pointer"
                />
            </div>
            {isOpen && (
                <div className="absolute z-100 bg-white rounded shadow-lg border border-gray-10 mt-1 right-0" style={{ width: '279px' }}>
                    <div className="p-3 pt-2">
                        <SketchPicker
                            color={theme.extend?.colors?.[id]?.baseValue || defaultColor}
                            onChange={(color) => updateTheme(`extend.colors.${id}.baseValue`, color.hex.toUpperCase())}
                            disableAlpha={true}
                            presetColors={[
                                '#111111', '#BBBBBB', '#DDDDDD', '#FFFFFF',
                                '#1557FF', '#1581FF', '#00BA8D', '#0FBC5A', '#BE05FF', '#9207FF',
                                '#C018DB', '#611BF8', '#FA5B30', '#FF9B05', '#E4A501', '#FC3331',
                                '#FC7443', '#FAA623',
                            ]}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

// const TabButton = ({ children, active, onClick }) => {
//     const activeStyles = 'border-b-2 font-medium border-blue-500 text-blue-600 -mb-px';
//     const inactiveStyles = 'hover:text-blue-600 hover:border-blue-300';
//     return (
//         <button
//             type='button'
//             onClick={onClick}
//             className={`px-4 py-2 text-sm ${active ? activeStyles : inactiveStyles}`}
//         >
//             {children}
//         </button>
//     );
// };