import React, { useCallback, useEffect, useState } from "react";
import { useApp, useUser } from "../hooks";
import defaultAvatar from "../img/Avatar.svg";
import { LoginModal } from "./Login";
import { Button } from "./Button";
import { Spinner } from "./Spinner";
import { Tooltip } from "./Tooltip";

export const UserAvatar = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { avatar } = useUser();
    const { setModal, setIsModalOpen } = useApp();
    const { isLoggedIn, handleLogout } = useUser();

    const handleClose = () => {
        setIsModalOpen(false);
        setModal(null);
    }

    const handleConfirm = () => {
        handleLogout();
        handleClose();
    }

    const handleLogoutModal = () => {
        setModal({
            title: 'Do you want to log out?',
            text: `If you log out now, any unsaved work may be lost. 
                You can log back in anytime. 
                Do you want to log out?`,
            cancelText: 'Cancel',
            confirmText: 'Log out',
            onClose: handleClose,
            onCancel: handleClose,
            onConfirm: handleConfirm
        })
        setIsModalOpen(true);
    }

    const handleLoginModal = () => {
        setModal({
            size: 'login',
            content:
                <LoginModal />
        })
        setIsModalOpen(true);
    }

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <div className="relative flex flex-row justify-center items-center">
            {isLoggedIn === null &&
                <div className="w-[40px] h-[40px] flex justify-center items-center grayscale opacity-40">
                    <Spinner />
                </div>
            }
            {isLoggedIn &&
                <Tooltip position="center" margin="mt-[40px]" message="Log out" className="h-[40px]">
                    <button onClick={handleLogoutModal}>
                        <img src={avatar ? avatar : defaultAvatar} alt="Avatar" className="rounded-full w-[40px] h-[40px]" />
                    </button>
                </Tooltip>
            }
            {isLoggedIn === false &&
                <Button primary={true} label="Log in" className="rounded" onClick={handleLoginModal} />
            }
        </div>
    );
};
