import React, { createContext, useRef, useEffect, useCallback, useState } from 'react';
import { createRoot } from 'react-dom/client';
import html2canvas from 'html2canvas';
import { openDB } from 'idb';

import { useTheme, useCode } from '../hooks';

const MAX_HISTORY = 100;
export const SnapshotContext = createContext();

// General functions
const initDB = async () => {
    const db = await openDB('webcrumbs-editor', 1, {
        upgrade(db) {
            db.createObjectStore('snapshots', {
                keyPath: 'id',
                autoIncrement: true
            });
        }
    });
    return db;
};

const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), wait);
    };
};

const adaptCssForSnapshot = (cssText) => {
    const styleSheet = new CSSStyleSheet();
    styleSheet.replaceSync(cssText);

    let resultCss = '';
    for (const rule of styleSheet.cssRules) {
        if (rule.style && rule.selectorText.includes('#webcrumbs')) {
            for (const property of rule.style) {
                rule.style.setProperty(property, rule.style.getPropertyValue(property), 'important');
            }
            resultCss += rule.cssText;
        }
    }

    return resultCss;
};

const adaptCodeForSnapshot = (code) => {
    const replacements = [
        { regex: /id: "/g, replacement: 'id: "snap-' },
        { regex: /snap-webcrumbs/g, replacement: 'webcrumbs' },
        { regex: /inset-0/g, replacement: '' },
        { regex: /fixed/g, replacement: 'absolute' }
    ];

    let resultCode = code;
    replacements.forEach(({ regex, replacement }) => {
        resultCode = resultCode.replace(regex, replacement);
    });

    const imgRegex = /React\.createElement\("img",\s*\{([^}]*)\}/g;
    resultCode = resultCode.replace(imgRegex, (match, attributes) => {
        if (attributes.includes('className')) {
            return match.replace(/className:\s*"([^"]*)"/, `className: "$1 bg-gray-300"`);
        } else {
            return match.replace(/\{/, `{ className: "bg-gray-300",`);
        }
    });

    return resultCode;
}

const createSnapshotClone = (css, size) => {
    const existingClone = document.getElementById('snapshot-clone');
    if (existingClone) {
        try { document.body.removeChild(existingClone); }
        catch { }
    }

    const clone = document.createElement('div');
    clone.id = 'snapshot-clone';
    clone.innerHTML = `
        <div id="webcrumbs" class="container" style="width: ${size.width}px;">
            <div class="content" id="content"></div>
        </div>
    `;

    const styleElement = document.createElement('style');
    styleElement.textContent = adaptCssForSnapshot(css);
    clone.appendChild(styleElement);

    return clone;
};

const renderSnapshotComponent = async (clone, transformedCode, generateComponent, componentStatus) => {
    const contentElement = clone.querySelector('#webcrumbs');

    if (componentStatus === 'rendered') {
        const root = createRoot(contentElement.querySelector('#content'));
        const adaptedCode = adaptCodeForSnapshot(transformedCode);
        const ResultingComponent = generateComponent(adaptedCode);
        root.render(<ResultingComponent />);

        await new Promise(resolve => setTimeout(resolve, 0));
    }
    return contentElement;
};

export const SnapshotProvider = ({ children }) => {
    const { code, codeAuthor, setCode, codeStatus, setCodeStatus, Component, componentStatus, size, setSize, transformedCode, generateComponent } = useCode();
    const { theme, themeAuthor, themeStatus, setThemeStatus, css, updateFullTheme } = useTheme();

    const [storeVersion, setStoreVersion] = useState(0);
    const [snapDescription, setSnapDescription] = useState();
    const [snapStatus, setSnapStatus] = useState();
    const [cursor, setCursor] = useState(null);
    const dbRef = useRef(null);

    useEffect(() => {
        const setupDB = async () => {
            dbRef.current = await initDB();
        };
        setupDB();
    }, []);

    useEffect(() => {
        const getLastKey = async () => {
            if (!dbRef.current) {
                dbRef.current = await initDB();
            }
            const db = dbRef.current;
            const tx = db.transaction('snapshots', 'readonly');
            const store = tx.objectStore('snapshots');
            const snapshots = await store.getAll();

            if (snapshots.length > 0) {
                const lastKey = snapshots[snapshots.length - 1].id;
                setCursor(lastKey);
            }

            setStoreVersion(prev => prev + 1);
        };

        getLastKey();
    }, []);

    const storeSnapshot = async (dataUrl, codeAuthor, themeAuthor, snapDescription, code, css, codeStatus, themeStatus, theme, width, force = false) => {
        const db = dbRef.current;
        const tx = db.transaction('snapshots', 'readwrite');
        const store = tx.objectStore('snapshots');
        const snapshots = await store.getAll();

        if (!force && snapshots.length > 0) {
            const lastSnapshot = snapshots[snapshots.length - 1];
            if (lastSnapshot.code === code && lastSnapshot.css === css) {
                return;
            }
        }

        const count = await store.count();
        if (count >= MAX_HISTORY) {
            const cursor = await store.openCursor();
            if (cursor) {
                await cursor.delete();
            }
        }

        const new_status = {
            timestamp: new Date(),
            snapDescription,
            code,
            codeAuthor,
            codeStatus,
            theme,
            themeAuthor,
            css,
            themeStatus,
            image: dataUrl,
            width
        };

        const id = await store.add(new_status);

        setCursor(id);
        setStoreVersion(prev => prev + 1);
        setSnapStatus('stored');
        return id;
    };

    const takeSnapshotWithCurrentState = useCallback(async () => {
        setSnapStatus('loading');

        if (css && code && theme) {
            const clone = createSnapshotClone(css, size);
            document.body.appendChild(clone);

            const contentElement = await renderSnapshotComponent(clone, transformedCode, generateComponent, componentStatus);

            const canvas = await html2canvas(contentElement, { backgroundColor: null });
            const dataUrl = canvas.toDataURL();

            let id;
            if (dataUrl || dataUrl !== 'data:,') {
                id = await storeSnapshot(
                    dataUrl, codeAuthor, themeAuthor, snapDescription, code, css, codeStatus, themeStatus, theme, size?.width
                );
                setSnapDescription(null);
                setCodeStatus('snapped');
                setThemeStatus('snapped');
                setSnapStatus('snapped');
            }
            if (clone) {
                try { document.body.removeChild(clone); }
                catch { }
            }
            return id;
        }
    }, [codeAuthor, themeAuthor, snapDescription, Component, componentStatus, size, code, css, codeStatus, themeStatus, theme, setCodeStatus, setThemeStatus]);

    const takeSnapshot = useCallback(debounce(takeSnapshotWithCurrentState, 1000), [takeSnapshotWithCurrentState]);

    const forceTakeSnapshot = useCallback(async (forcedDescription) => {
        setSnapStatus('loading');

        const clone = createSnapshotClone(css, size);
        document.body.appendChild(clone);

        const contentElement = await renderSnapshotComponent(clone, transformedCode, generateComponent, componentStatus);

        const canvas = await html2canvas(contentElement, { backgroundColor: null });
        const dataUrl = canvas.toDataURL();

        let id;
        if (dataUrl || dataUrl !== 'data:,') {
            id = await storeSnapshot(
                dataUrl, codeAuthor, themeAuthor, forcedDescription, code, css, codeStatus, themeStatus, theme, size?.width, true
            );
            setSnapDescription(null);
            setCodeStatus('snapped');
            setThemeStatus('snapped');
            setSnapStatus('snapped');
        }
        if (clone) {
            try { document.body.removeChild(clone); }
            catch { }
        }
        return id;
    }, [codeAuthor, themeAuthor, Component, componentStatus, size, code, css, codeStatus, themeStatus, theme, setCodeStatus, setThemeStatus]);

    const getNthLastSnapshotId = async (n) => {
        if (!dbRef.current) {
            dbRef.current = await initDB();
        }

        const db = dbRef.current;
        const tx = db.transaction('snapshots', 'readonly');
        const store = tx.objectStore('snapshots');
        const snapshots = await store.getAll();

        if (snapshots.length < n) {
            return null;
        }

        const nthLastSnapshot = snapshots[snapshots.length - n];
        const id = nthLastSnapshot.id;

        return id;
    };

    const getSnapshotById = async (id) => {
        if (!dbRef.current) {
            dbRef.current = await initDB();
        }

        const db = dbRef.current;
        const tx = db.transaction('snapshots', 'readonly');
        const store = tx.objectStore('snapshots');
        const snapshot = await store.get(id);

        if (!snapshot) {
            return null;
        }

        return snapshot;
    };

    const navigateHistory = useCallback(async (newIndex) => {
        const db = dbRef.current;
        const tx = db.transaction('snapshots', 'readonly');
        const store = tx.objectStore('snapshots');

        try {
            const snapshot = await store.get(newIndex);
            if (snapshot) {
                setCursor(newIndex);
                setCode(snapshot.code);
                setSize({ ...size, width: snapshot.width });
                updateFullTheme(snapshot.theme);
            } else {
                console.warn('No snapshot available at index:', newIndex);
            }
        } catch (error) {
            console.warn('Error accessing snapshots:', error);
        }
    }, [setCursor, setCode, updateFullTheme]);

    const undo = useCallback(() => navigateHistory(cursor - 1), [cursor, navigateHistory]);
    const redo = useCallback(() => navigateHistory(cursor + 1), [cursor, navigateHistory]);

    const clearHistory = async () => {
        const db = dbRef.current;
        const tx = db.transaction('snapshots', 'readwrite');
        const store = tx.objectStore('snapshots');
        await store.clear();
        await tx.done;
        setStoreVersion(prev => prev + 1);
    };

    const getUndoRedoInfo = useCallback(async () => {
        if (!dbRef.current) {
            dbRef.current = await initDB();
        }
        const db = dbRef.current;
        const tx = db.transaction('snapshots', 'readonly');
        const store = tx.objectStore('snapshots');
        const allKeys = await store.getAllKeys();
        const minKey = allKeys.length > 0 ? allKeys[0] : null;
        const maxKey = allKeys.length > 0 ? allKeys[allKeys.length - 1] : null;

        return {
            canUndo: cursor > minKey,
            canRedo: cursor < maxKey,
            currentCursor: cursor,
            totalStates: maxKey,
            lastCursor: maxKey
        };
    }, [cursor]);

    return (
        <SnapshotContext.Provider value={{
            snapDescription, setSnapDescription,
            snapStatus, setSnapStatus,
            cursor, setCursor,
            takeSnapshot, getNthLastSnapshotId, getSnapshotById,
            navigateHistory, undo, redo, clearHistory,
            getUndoRedoInfo, storeVersion,
            forceTakeSnapshot,
            adaptCssForSnapshot
        }}>
            {children}
        </SnapshotContext.Provider>
    );
};
