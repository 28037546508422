import React, { Children, useEffect } from "react";
import { Button } from "./Button";
import tippy_white from "../img/tippy-white.svg";
import quit from  "../img/quit.svg"
import { useApp } from "../hooks";

export const Nudge = ({
    cancelText,
    confirmText,
    onClose,
    onCancel,
    onConfirm,
    position,
    children,
    id,
}) => {
    const { openNudge } = useApp();



    if (openNudge === id) {
        return (
            <div className="relative" onClick={(event) => event.stopPropagation()}>
                <div className={`absolute ${position}`}>
                    <div className="relative flex flex-col items-center">
                        <div className="w-[241px] h-[122px] bg-white p-4 rounded-[12px] z-100">
                            <button className={`absolute w-[16px] h-[16px] top-[16px] right-[16px] text-gray-500 hover:text-gray-700`} onClick={onClose}>
                                <img src={quit} className="h-[16px] w-[16px]" alt="Quit"/>
                            </button>
                            <div className="flex items-center mb-4">
                                <div className="text-gray-01 font-semibold text-[14px]">
                                    {children}
                                </div>
                            </div>
                            <div className="flex justify-between items-center">
                                <button className="text-gray-01" onClick={onCancel}>{cancelText}</button>
                                <Button
                                    style={{
                                        width: "86px",
                                        height: "32px",
                                        fontSize: "14px",
                                        whiteSpace: "nowrap",
                                    }}
                                    className="text-white font-bold rounded-[12px]"
                                    primary="primary"
                                    label={confirmText}
                                    onClick={onConfirm}
                                />
                            </div>
                        </div>
                        <div className="w-full flex justify-start mt-[-10px]">
                            <img
                                src={tippy_white}
                                alt="Tippy"
                                className="w-[100px] h-[10px] mt-[10px]"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};
