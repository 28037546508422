import React, { createContext, useEffect, useState } from 'react';
import { useTheme } from '../hooks';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [codeOpen, setCodeOpen] = useState(false);
    const [rightPanelOpen, setRightPanelOpen] = useState(false);
    const [emptyState, setEmptyState] = useState(true);
    const [modal, setModal] = useState({
        title: null,
        content: null,
        cancelText: null,
        confirmText: null,
        onClose: null,
        onCancel: null,
        onConfirm: null
    });
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [openNudge, setOpenNudge] = useState();

    // const [toasts, setToasts] = useState([
    //     {
    //         isOpen: false,
    //         type: null,
    //         content: null
    //     }
    // ]);

    const { classes } = useTheme();

    useEffect(() => {
        if (classes.length > 0) {
            setEmptyState(false);
            setRightPanelOpen(true);
        } else {
            setRightPanelOpen(false);
        }
    }, [classes])

    return (
        <AppContext.Provider value={{
            codeOpen, setCodeOpen,
            emptyState, setEmptyState,
            modal, setModal, isModalOpen, setIsModalOpen,
            rightPanelOpen, setRightPanelOpen,
            openNudge, setOpenNudge
        }}>
            {children}
        </AppContext.Provider>
    );
};
