import React from "react";
import checkbox from "../../img/checkbox.svg";
import { SVG } from "../SVG";

export default function Checkbox({ checked, onChange }) {
  return (
    <div className={`checkbox-container`}>
      <label className="checkbox-container">
        <label className="relative flex items-center mr-[4px] rounded cursor-pointer" htmlFor="link">
          <input
            type="checkbox"
            className="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded-[4px] border border-gray-10 transition-all before:absolute 
            before:top-2/4 before:left-2/4 before:block before:h-4 before:w-4 before:-translate-y-2/4 before:-translate-x-2/4 
            before:rounded before:bg-gray-10 before:opacity-0 before:transition-opacity checked:bg-primary checked:before:bg-gray-10 hover:before:opacity-10"
            id="link"
            checked={checked}
            onChange={onChange}
          />
          <span className="absolute text-gray-10 opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100 transition-opacity duration-300 ease-in-out">
            <div className="w-4 h-4 transition-transform duration-300 ease-in-out transform scale-75 rotate-0 peer-checked:scale-110 peer-checked:rotate-45 peer-checked:translate-y-1 peer-checked:translate-x-1">
              <SVG replace={['FA5B30', '#06182F']} src={checkbox} alt="checkbox"/>
            </div>
          </span>
        </label>
      </label>
    </div>
  );
}
