import React, { useEffect, useRef, useState } from "react";
import { useCode, useSnapshot, useTheme } from "../hooks";

import down from '../img/down.svg';
import close from '../img/icons/close.svg';

export const ThemeSelector = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { theme, themes, updateFullTheme } = useTheme();
    const { setCodeStatus } = useCode();
    const { setSnapDescription } = useSnapshot();
    const dropdownRef = useRef(null);

    // Close dropdown menu when click outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef, setIsOpen]);

    // Function to handle selectioon of new these
    const handleSelectTheme = (theme) => {
        updateFullTheme(theme);
        setSnapDescription('Changed theme');
        setCodeStatus('rendered');
        setIsOpen(false);
    }

    return (
        <div className="mb-4 relative" ref={dropdownRef}>
            <label className="block text-sm font-medium text-gray-01">
                Select theme
            </label>
            <button
                type='button'
                onClick={() => setIsOpen(!isOpen)}
                className="flex justify-between items-center mt-1 block w-full rounded border border-gray-10 bg-white h-[40px] px-3 text-left sm:text-sm"
            >
                {theme.name}
                <img src={down} alt="down" />
            </button>
            {isOpen && (
                <div className="absolute z-100 bg-white rounded shadow-lg border border-gray-10 p-2 pt-0 mt-1 right-0 overflow-auto" style={{ minWidth: '288px', maxHeight: '388px' }}>
                    <div className="flex justify-between space-x-1">
                        <div className="text-base p-4">Themes</div>
                        <button type='button' onClick={() => setIsOpen(false)} className="opacity-70 hover:opacity-100 pr-3">
                            <img src={close} />
                        </button>
                    </div>
                    <div className="grid grid-cols-3 gap-3 m-2 mt-0">
                        {themes.map((theme, index) => (
                            <div key={index} className="group p-1 pt-2 border border-gray-10 hover:bg-gray-11 text-gray-02 rounded-lg flex flex-col items-center cursor-pointer" onClick={() => handleSelectTheme(theme)}>
                                <ThemeIlustration id={index} theme={theme} />
                                <span className="text-xs py-1">{theme.name}</span>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};


const ThemeIlustration = ({ theme }) => {
    const background = theme.extend?.colors?.neutral?.[50] || "#FFFFFF";
    const primaryColor = theme.extend?.colors?.primary?.[300] || "#000000";
    const outline = theme.extend?.colors?.neutral?.[600] || "#666";
    const border = theme.extend?.colors?.neutral?.[900] || "#666";
    const text = "#666";
    const sidebar = background;
    const borderRadius = "6px";

    return (
        <div style={{ background: background, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', borderRadius: borderRadius }}
            className="transform transition-transform group-hover:-translate-y-[2px]"
        >
            <svg width="64" height="48" viewBox="0 0 64 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="63" height="47" rx="3.5" stroke={outline} />
                <rect x="36" y="8" width="20" height="32" rx="2" fill={sidebar} />
                <rect x="36" y="8" width="20" height="32" rx="2" stroke={border} />
                <rect x="40" y="15" width="12" height="1.2" rx="0.6" fill={text} />
                <rect x="40" y="18.2" width="12" height="1.2" rx="0.6" fill={text} />
                <rect x="40" y="21.4" width="10" height="1.2" rx="0.6" fill={text} />
                <rect x="40" y="24.6" width="10" height="1.2" rx="0.6" fill={text} />
                <rect x="8" y="16" width="20" height="1.2" rx="0.6" fill={primaryColor} />
                <rect x="8" y="19.2" width="20" height="1.2" rx="0.6" fill={primaryColor} />
                <rect x="8" y="22.4" width="18" height="1.2" rx="0.6" fill={primaryColor} />
                <rect x="8" y="25.6" width="18" height="1.2" rx="0.6" fill={primaryColor} />
                <defs>
                    <pattern id="pattern0_48_12199" patternContentUnits="objectBoundingBox" width="1" height="1">
                        <use xlinkHref="#image0_48_12199" transform="matrix(0.00108696 0 0 0.00144928 0 -0.166667)" />
                    </pattern>
                </defs>
            </svg>
        </div>
    )

}
