import { Button } from "../components/Button";
import discord_icon from "../img/discord.svg";
import github_icon from "../img/github_black.svg";

export const ctas = {
    "top_right_menu": <div className="flex items-center gap-3">
        <Button
            icon={github_icon}
            onClick={() =>
                window.open(
                    "https://github.com/webcrumbs-community/webcrumbs",
                    "_blank"
                )
            }
            className="rounded"
            label="Learn more"
        />
    </div>
}