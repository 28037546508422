
export const template = `
<div id="webcrumbs" className="w-[375px] shadow rounded-lg bg-neutral-50 overflow-hidden">
  <img src="https://via.placeholder.com/375x200" alt="Placeholder" className="w-full" />
  <div className="p-4 flex flex-col justify-between" style={{ minHeight: '450px' }}>
    <div className="px-4">
      <h1 className="font-title text-lg font-semibold">BMW Template Example</h1>
      <div className="flex items-center mt-2">
        <span className="material-symbols-outlined">calendar_today</span>
        <p className="ml-2">27 November, 2024</p>
      </div>
      <div className="flex items-center mt-2">
        <span className="material-symbols-outlined">schedule</span>
        <p className="ml-2">11:00 - 19:00</p>
      </div>
      <div className="flex items-center mt-2">
        <span className="material-symbols-outlined">location_on</span>
        <p className="ml-2">Dijon, France</p>
      </div>
    </div>
    <div className="mt-4 p-4 bg-neutral-100 rounded-md shadow">
      <p className="font-medium mb-2">Participant:</p>
      <p className="flex items-center">
        <span className="material-symbols-outlined">person</span>
        <span className="ml-2">Ronald Richards</span>
      </p>
      <p className="flex items-center mt-2">
        <span className="material-symbols-outlined">email</span>
        <span className="ml-2">tim.jennings@example.com</span>
      </p>
      <p className="flex items-center mt-2">
        <span className="material-symbols-outlined">phone</span>
        <span className="ml-2">(808) 555-0111</span>
      </p>
    </div>
    <div className="flex-grow mt-4"></div>
    <div className="mt-6">
      <button className="h-[50px] w-full bg-primary-500 text-white rounded-md">Register presence</button>
    </div>
  </div>
</div>
`