// src/components/LeftPanel/index.js
import { useImage } from "../../hooks"
import { LeftPrimaryPanel } from "./LeftPrimaryPanel"
import { LeftSecondaryPanel } from "./LeftSecondaryPanel"

export const LeftPanel = () => {
    const { isOpen } = useImage();

    return (
        <div className={`flex flex-row relative transition-all duration-300 ease-in-out ${isOpen ? 'w-[378px]' : 'w-[78px]'}`}>
            <LeftPrimaryPanel />
            <LeftSecondaryPanel />
        </div>
    )
}
