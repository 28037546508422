import { UndoRedo } from "./UndoRedo";
import packageJson from "../../package.json";

import React from "react";
import { CopyCode } from "./CopyCode";
import { useApp, useCustomization } from "../hooks";
import share from "../img/share_gray.svg"
import { UserAvatar } from "./UserAvatar";
import { Button } from "./Button";
// import { ShareModal } from "./Share/ShareModal";
// import google_icon from "../img/google.svg"; // Adicione o ícone do Google

export const NavBar = () => {
    // const { setModal, setIsModalOpen } = useApp();
    const { images, ctas } = useCustomization();

    // const handleShare = () => {
    //     setModal({ size: 'xlarge', content: <ShareModal /> });
    //     setIsModalOpen(true);
    // }

    return (
        <div className="w-full h-[64px] px-8 py-3 bg-white border-b border-gray-10 flex justify-between items-center">
            <div className="flex items-center gap-8">
                <div className="flex flex-row gap-[8px] items-center justify-center">
                    {images.main_logo}
                    <div className="h-[20px] px-2 py-0.5 bg-gray-10 rounded-xl justify-start items-center inline-flex">
                        <div className="text-center text-gray-04 text-[10px] font-semibold leading-3">
                            Beta v {packageJson.version}
                        </div>
                    </div>
                </div>
                <UndoRedo />
            </div>
            <div className="flex items-center gap-4">
                <CopyCode />
                {/* <Button
                    icon={share}
                    onClick={handleShare}
                    className="rounded"
                    label="Share"
                /> */}
                {ctas.top_right_menu}
                <div>
                    <UserAvatar />
                </div>
            </div>
            <div className="absolute left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2">
                {/* <div className="flex">
                    <button className="px-4 py-2 bg-white border border-gray-300 rounded-l hover:bg-neutral-2 hover:stroke-black stroke-red">
                        <svg className="stroke-neutral-8" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.167 1.66602H5.83366C4.91318 1.66602 4.16699 2.41221 4.16699 3.33268V16.666C4.16699 17.5865 4.91318 18.3327 5.83366 18.3327H14.167C15.0875 18.3327 15.8337 17.5865 15.8337 16.666V3.33268C15.8337 2.41221 15.0875 1.66602 14.167 1.66602Z" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10 15H10.0083" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </button>
                    <button className="px-4 py-2 bg-white border-y border-r border-gray-300 hover:bg-neutral-2">
                        <svg className="stroke-neutral-8" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.625 1.66602H4.375C3.33947 1.66602 2.5 2.41221 2.5 3.33268V16.666C2.5 17.5865 3.33947 18.3327 4.375 18.3327H15.625C16.6605 18.3327 17.5 17.5865 17.5 16.666V3.33268C17.5 2.41221 16.6605 1.66602 15.625 1.66602Z" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10 15H10.0083" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </button>
                    <button className="px-4 py-2 bg-white border-y border-gray-300 hover:bg-neutral-2">
                        <svg className="stroke-neutral-8" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.49967 6.25065H12.4997M4.66634 3.33398H15.333C16.0694 3.33398 16.6663 4.08018 16.6663 5.00065V13.334C16.6663 14.2545 16.0694 15.0007 15.333 15.0007H4.66634C3.92996 15.0007 3.33301 14.2545 3.33301 13.334V5.00065C3.33301 4.08018 3.92996 3.33398 4.66634 3.33398Z" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M18.3337 14.166H1.66699V15.666C1.66699 16.2183 2.11471 16.666 2.66699 16.666H17.3337C17.8859 16.666 18.3337 16.2183 18.3337 15.666V14.166Z" fill="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </button>
                    <button className="px-4 py-2 bg-white border border-gray-300 rounded-r hover:bg-neutral-2">
                        <svg className="stroke-neutral-8" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.667 2.5H3.33366C2.41318 2.5 1.66699 3.24619 1.66699 4.16667V12.5C1.66699 13.4205 2.41318 14.1667 3.33366 14.1667H16.667C17.5875 14.1667 18.3337 13.4205 18.3337 12.5V4.16667C18.3337 3.24619 17.5875 2.5 16.667 2.5Z" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M6.66699 17.5H13.3337" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10 14.166V17.4993" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </button>
                </div> */}
            </div>
        </div>
    );
};
