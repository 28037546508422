// src/components/Code/index.js
import React, { useEffect, useState } from "react";
import up from '../../img/chevron-up.svg';
import by_webcrumbs from "../../img/by_webcrumbs.svg";
import { ComponentCode } from "./ComponentCode";
import { StyleCode } from "./StyleCode";
import { useAi, useApp } from "../../hooks";
import { Nudge } from "../Nudge";

export const Code = () => {
    const { codeOpen, setCodeOpen, setOpenNudge } = useApp();
    const { result } = useAi();
    const [alreadyNudged, setAlreadyNudged] = useState([]);

    useEffect(() => {
        const nudgeList = localStorage.getItem('alreadyNudged');
        if (nudgeList) {
            setAlreadyNudged(JSON.parse(nudgeList));
        }
    }, []);

    useEffect(() => {
        if (result && !alreadyNudged.includes('code-ready')) {
            setOpenNudge('code-ready');
            const updatedNudges = [...alreadyNudged, 'code-ready'];
            setAlreadyNudged(updatedNudges);
            localStorage.setItem('alreadyNudged', JSON.stringify(updatedNudges));
        }
    }, [result, alreadyNudged, setOpenNudge]);

    const handleConfirm = () => {
        setCodeOpen(true);
        setOpenNudge(null);
    };

    return (
        <div className="w-full rounded-t-[10px] flex flex-col justify-center bg-code text-white fixed bottom-0 left-0 right-0 z-200">
            <div className="w-full flex flex-row justify-between cursor-pointer" onClick={() => setCodeOpen(!codeOpen)}>
                <div className="px-[24px] flex flex-row items-center h-[56px]">
                    <div className="font-semibold">
                        <Nudge id="code-ready" position="-top-[130px] -right-[300px]" cancelText="Skip" confirmText="Let's see"
                            onConfirm={handleConfirm}
                            onClose={() => setOpenNudge(null)} onCancel={() => setOpenNudge(null)}>
                            👀 Peek at the AI magic! <br />
                            Your code awaits.
                        </Nudge>
                        Code
                    </div>
                    <div className="pl-[8px]">
                        <img src={up} alt="up" className={`h-[20px] w-[20px] ${codeOpen && 'rotate-180'}`} />
                    </div>
                </div>
                <a className="px-[24px] flex flex-row items-center h-[56px]" href={"https://webcrumbs.org/?ref=" + window.location.hostname} target="_blank" onClick={(e) => e.stopPropagation()}>
                    <img src={by_webcrumbs} alt="By Webcrumbs" className="h-[12px] opacity-40" />
                </a>
            </div>
            <div className={`flex flex-row w-full transition-all duration-300 ease-in-out`} style={{ height: codeOpen ? "calc(40vh)" : "0" }}>
                <ComponentCode size="w-3/5" />
                <StyleCode size="w-2/5" />
            </div>
        </div>
    );
};
